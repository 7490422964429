import React, {FC, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

//import { makeStyles } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import Layout from '../Layout/Layout'

import { useValidPassword, useValidUsername } from '../hooks/useAuthHooks'
import { Password, Username } from '../widgets/authComponents'

import { AuthContext } from './authContext'


const AdminSignIn: FC = () => {
/*
  const classes = makeStyles({
    root: {
      height: '100vh',
    },
    hover: {
      '&:hover': { cursor: 'pointer' },
    },
  })
  
className={classes.root}
className={classes.hover}

*/
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const authContext = useContext(AuthContext)
  
  const navigate = useNavigate()

  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(username, password)
      navigate('/')
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        console.log(err)
        navigate('/AdminVerify')
      } else {
        setError(err.message)
        console.log(err)
      }
    }
  }

  const passwordResetClicked = async () => {
    navigate('requestcode')
    console.log('clickedk password reset')
  }

  return (
    <Layout>
      <Grid container direction="row" alignItems="center" justifyContent="center" height="100%" width="100%">
        <Grid xs={11} sm={6} lg={4} container direction="row" alignItems="center" item>
          <Paper style={{ width: '100%', padding: 32 }}>
            <Grid container direction="column" alignItems="center">
              {/* Title */}
              <Box m={2}>
                <Typography variant="h3">Sign in</Typography>
              </Box>

              {/* Sign In Form */}
              <Box width="80%" m={1}>
                {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
                <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
              </Box>
              <Box width="80%" m={1}>
                <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
                <Grid container direction="row" alignItems="center">
                  <Box onClick={passwordResetClicked} mt={2}>
                    <Typography  variant="body2">
                      Forgot Password?
                    </Typography>
                  </Box>
                </Grid>
              </Box>

              {/* Error */}
              <Box mt={2}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Box>

              {/* Buttons */}
              <Box mt={2}>
                <Grid container direction="row">
                  <Box m={1}>
                    <Button color="secondary" variant="contained" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </Box>
                  <Box m={1}>
                    <Button disabled={isValid} color="primary" variant="contained" onClick={signInClicked}>
                      Sign In
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AdminSignIn

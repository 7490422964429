import {ApiOrganization} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'


interface IUseOrganization {
  organizationId: string
}
const getOrganization = async ({organizationId}: IUseOrganization) => {
  console.log('FETCH: Fetching Organization - ', organizationId)
  const {data} = await apiInterface.get<ApiOrganization>('getOrganization', {
    params: {
      organizationId
    }
  })
  
  return data
}

export const updatetOrganization = async(organizationInfo: ApiOrganization) => {
  console.log('UPDATE: Updating Organization - ', organizationInfo)
  const {data} = await apiInterface.post<ApiOrganization>('updateOrganization',    
      JSON.stringify(organizationInfo)
  )
  
  return data
}


export const useInvalidateOrganization = ({organizationId}: IUseOrganization) => {
  
  const queryValues = [
    queryKeys.GET_ORGANIZATION,
    organizationId,
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateOrganization = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateOrganization
  }
}

export const useOrganization = ({organizationId,}: IUseOrganization) => {
  const {queryValues} = useInvalidateOrganization({organizationId})
  
  return useQuery<ApiOrganization>(
    queryValues,
    () => getOrganization({organizationId}),
    {
      ...queryOptions,
      enabled: !!organizationId,
    }
  )
}
import React, {FC, useState} from 'react'
import {Button, TextField, Typography} from '@mui/material'
import {parseTeamName, parseDivisionId} from '../utils/utils'
import TeamCardLayout from './TeamCardLayout'
import {ApiTeam} from '../types/apiTypes'
import {updateTeam} from '../hooks/useTeam'

type DefaultTeamCardProps = {
  teamInfo: ApiTeam
  showCoachInfo?: boolean,
  isEditable?: boolean, 
}

const DefaultTeamCard: FC<DefaultTeamCardProps> = ({
                                                    teamInfo,
                                                    showCoachInfo,
                                                    isEditable
                                                  }) => {

  const {year, divisionTeamName, divisionId, organizationId, teamName, coachEmail, coachName} = teamInfo
  const yearDivisionTeamName = `${year}-${divisionId}-${teamName}`

  const [_coachName, setCoachName] = useState('');
  const [_coachEmail, setCoachEmail] = useState('');

  const handleSubmit = (event: { preventDefault: () => void }) => {
    console.log('handleSubmit ran');
    event.preventDefault(); // 👈️ prevent page refresh

    // 👇️ access input values here
    console.log('coachName 👉️', _coachName);
    console.log('coachEmail 👉️', _coachEmail);

    let teamSubmitData: ApiTeam = {
      year: year,
      divisionTeamName: divisionTeamName,
      divisionId: divisionId,
      organizationId: organizationId,
      teamName: teamName,
      coachName: _coachName,
      coachEmail: _coachEmail,
      //wins?: number,
      //losses?: number,
      //ties?: number,
      //mercyLosses?: number,
      //mercyWins?: number,
      //playoffPoints?: number,
      //pointsScored?: number,
      //pointsAgainst?: number,
    }
    console.log(teamSubmitData)

    let returnValue = updateTeam(teamSubmitData)
    console.log('returnValue: ' + returnValue)    

  }

  return (
    <TeamCardLayout yearDivisionTeamName={yearDivisionTeamName} isEditable={isEditable}>
        <Typography variant={'h5'} component={'div'} className={'text-center'}>
          {parseDivisionId(divisionId)} - {parseTeamName(teamName)}
        </Typography>
        {showCoachInfo ? (
            isEditable ?
            (
              <form onSubmit={handleSubmit}>
                <TextField id="coach_name" margin='normal' fullWidth size="small" onChange={event => setCoachName(event.target.value)} label="Head Coach" value={coachName} />
                <TextField id="coach_email" type='email' margin='normal' fullWidth size="small" onChange={event => setCoachEmail(event.target.value)} label="Email" value={coachEmail} />
                <Button variant='outlined' type="submit">Save</Button>
              </form>             
            ) :
            (
              <>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Head Coach: {coachName}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Email: {coachEmail}
                </Typography>
              </>
            )
          ) : ('')}
    </TeamCardLayout>
  )
}

export default DefaultTeamCard

/*
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Head Coach: {coachName && {coachName}}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Email: {coachEmail && {coachEmail}}
                </Typography>

*/
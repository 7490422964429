import React, {FC, useState, useContext} from 'react'
import Card from '@mui/material/Card/Card'
import {Box, Button, CardContent, Grid, TextField, Typography, responsiveFontSizes, createTheme} from '@mui/material'
import {updateGame} from '../hooks/useGame'
import {ApiGame} from '../types/apiTypes'
import {parseDivisionId, parseTeamName} from '../utils/utils'
import {ThemeProvider} from '@emotion/react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import {AuthContext} from './authContext'

type AdminUpdateScoreRowProps = {
  game: ApiGame
}

enum UpdateStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error'
}

const AdminUpdateScoreRow: FC<AdminUpdateScoreRowProps> = ({game}) => {
  
  const auth = useContext(AuthContext)
  //console.log(`auth: ${JSON.stringify(auth)}`)
  const authUserOrgId = auth.organizationId
  const isAdmin = auth.groups.includes('admins')
  console.log(`isAdmin: ${isAdmin}`)
  const isHomeRep = authUserOrgId === game.homeOrganizationId  || (authUserOrgId === game.awayOrganizationId && game.homeOrganizationId === 'None' ) //account for away 'bye' games
  console.log(`isHomeRep: ${isHomeRep}`)

  const [updateStatus, setUpdateStatus] = useState(UpdateStatus.IDLE)
  
  const [updatesEnabled, setUpdatesEnabled] = useState(isAdmin || (isHomeRep && !game.homeTeamScore))
  const [homeTeamScore, setHomeTeamScore] = useState(game.homeTeamScore)
  const [awayTeamScore, setAwayTeamScore] = useState(game.awayTeamScore)
  
  const submitUpdateScoreForm = () => {
    setUpdateStatus(UpdateStatus.PENDING)
    updateGame({...game, homeTeamScore, awayTeamScore})
      .then((response) => {
        if (response.success) {
          setUpdateStatus(UpdateStatus.SUCCESS)
          console.log(response.message)
        } else {
          setUpdateStatus(UpdateStatus.ERROR)
          console.error(response.message)
        }
      })
  }
  
  let header = parseDivisionId(game.divisionId)
  header += `: ${game.gameDate} at ${game.gameTime}`
  
  let theme = createTheme()
  theme = responsiveFontSizes(theme)
  
  return (
    <Grid item lg={12} md={12} xs={12} rowSpacing={3} key={game.gameId}>
      <Card variant="outlined">
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={submitUpdateScoreForm}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h5" component="div" align="center">
                {header}
              </Typography>
            </ThemeProvider>
            <Grid container>
              <Grid item lg={5} md={5} xs={5}>
                <TextField
                  id={`${game.gameId}_${game.homeTeamName}`}
                  onChange={e => {
                    setUpdateStatus(UpdateStatus.IDLE)
                    setHomeTeamScore(e.target.value)
                  }}
                  margin="normal"
                  fullWidth
                  size="small"
                  label={parseTeamName(game.homeTeamName)}
                  value={homeTeamScore}
                  disabled={!updatesEnabled}
                />
              </Grid>
              { /* TODO get this to align in the center better */}
              <Grid item container lg={2} md={2} xs={2} alignItems="center" justifyContent="center">
                <Typography variant="h5" component="div">
                  {'vs.'}
                </Typography>
              </Grid>
              <Grid item lg={5} md={5} xs={5}>
                <TextField
                  id={`${game.gameId}_${game.awayTeamName}`}
                  onChange={e => {
                    setUpdateStatus(UpdateStatus.IDLE)
                    setAwayTeamScore(e.target.value)
                  }}
                  margin="normal"
                  fullWidth
                  size="small"
                  label={parseTeamName(game.awayTeamName)}
                  value={awayTeamScore}
                  disabled={!updatesEnabled}
                />
              </Grid>
            </Grid>
            <Grid alignItems="center" justifyContent="center">
              {
                updatesEnabled &&
                <Grid container justifyContent="center">
                  <Button id={game.sharedGameId} variant="outlined" onClick={submitUpdateScoreForm}>Save</Button>
                </Grid>
              }
              {
                updateStatus === UpdateStatus.PENDING &&
                <Typography variant="body1" component="div" align="center" paddingTop="8px">
                  {'Saving...'}
                </Typography>
              }
              {
                updateStatus === UpdateStatus.SUCCESS &&
                <Typography variant="body1" component="div" align="center" paddingTop="8px">
                  <CheckCircleOutlineIcon color={'success'}/>
                </Typography>
              }
              {
                updateStatus === UpdateStatus.ERROR &&
                <Typography variant="body1" component="div" align="center" paddingTop="8px">
                  <ErrorOutlineIcon color={'error'}/>
                </Typography>
              }
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default AdminUpdateScoreRow

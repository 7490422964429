import React, {FC, PropsWithChildren} from 'react'
import {Link} from 'react-router-dom'
import {Card} from '@mui/material'

type TeamCardLayoutProps = {
  yearDivisionTeamName: string,
  children : PropsWithChildren,
  isEditable?: boolean
}

const TeamCardLayout: FC<PropsWithChildren<TeamCardLayoutProps>> = ({
                                                    yearDivisionTeamName, 
                                                    children,
                                                    isEditable
                                                  }) => {


  return (
    <>
      {isEditable ? (
        <Card variant={'outlined'} className={'my-2 p-4'}>
          {children}
        </Card>
    ) : (
        <Link to={`/teams/${yearDivisionTeamName}`} key={yearDivisionTeamName}>
          <Card variant={'outlined'} className={'my-2 p-4'}>
            {children}
          </Card>
        </Link>
      )}
      </>
  )
}
export default TeamCardLayout
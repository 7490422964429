import React, {FC, useState} from 'react'
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material'
import Layout from '../Layout/Layout'
//import { AuthContext } from './authContext' 

import AdminWeeklyScoreWidget from './AdminWeeklyScoreWidget'
import { parseDivisionId } from '../utils/utils'

const AdminWeeklyScoreReport: FC = () => {
    
  //const auth = useContext(AuthContext)
  const [selectedWeek, setTargetWeek] = useState<string>('1')

  const ageGroups = [
    'varsity',
    'jv',
    '11u',
    '10u',
    '9u',
  ]
  
  const divisions = [
    'gold_d1',
    'gold_d2',
    'silver'
  ]
  
  let totalDivisions = ageGroups.flatMap(ageGroup => {
    return divisions.map(division => {
      return `${ageGroup}_${division}`
    })
  })
  
  totalDivisions = [...totalDivisions, '8u', 'flag']
  
  const handleWeekChange = (event: SelectChangeEvent) => {
    setTargetWeek(event.target.value)
  }

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3} rowSpacing={3} columnSpacing={3} padding={3}>
        <Grid item md={3} xs={0}/>
        <Grid item container md={6} xs={12}>
          <Grid item container md={6} xs={12} justifyContent="center">
            {/*}
            <FormControl sx={{m: 1, minWidth: 150}} size="small">
              <InputLabel id="demo-select-small">Division</InputLabel>
              <Select
                label="Division"
                onChange={handleDivisionChange}
                value={selectedDiv}
                >
                    {totalDivisions.map((division, index) => {
                    const divisionString = division.replace(/\s+/g, '-');
                    return (
                        <MenuItem key={division} value={divisionString}>
                            {division}
                        </MenuItem>
                    );
                    })}
              </Select>
            </FormControl>
            {*/}
          </Grid>
          <Grid item container md={6} xs={12} justifyContent="center">
            <FormControl sx={{m: 1, minWidth: 150}} size="small">
              <InputLabel id="demo-select-small">Date</InputLabel>
              <Select
                label="Date"
                onChange={handleWeekChange}
                value={selectedWeek}
                >
                  {globalThis.app.WEEKS.map((week) => (
                    <MenuItem key={week.value} value={week.value}>
                      {week.label}
                    </MenuItem> 
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={3} xs={0}/>
            <Grid item lg={4} md={6} xs={12} rowSpacing={3}>
                {    totalDivisions.map(division => (
                  <Grid container>
                    <Typography variant='h5' sx={{padding: '10px'}}>{parseDivisionId(division)}</Typography>
                    <AdminWeeklyScoreWidget divisionId={division} week={selectedWeek} />
                  </Grid>
                ))}
          </Grid>
      </Grid>
    </Layout>
  )
}

export default AdminWeeklyScoreReport

import React, {FC, useEffect, useState} from 'react'
import {Box, Tab, Tabs} from '@mui/material'
import TabComponent from '../TabComponent'
import AboutHistory from './AboutHistory'
import BillGeorge from './BillGeorge'
import Organizations from './Organizations'
import ExecutiveList from './ExecutiveList'
import {useNavigate} from 'react-router-dom'
import SuperBowlWinners from './SuperBowlWinners'
import Layout from '../Layout/Layout'

type AboutPageProps = {
  tabKey?: number
}

const AboutPage: FC<AboutPageProps> = ({
                                         tabKey
                                       }) => {
  
  useEffect(() => {
    if (tabKey) {
      setValue(tabKey)
    }
  }, [tabKey])
  
  const [value, setValue] = useState<number>(tabKey ? tabKey : 0)
  const navigate = useNavigate()
  
  const pages = ['history', 'billGeorge', 'franchises', 'executives', 'superBowlWinners']
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/about/${pages[newValue]}`)
    setValue(newValue)
  }
  
  return (
    <Layout>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab key={'history'} label={'History'} className={'bg-blue-600'}/>
          <Tab key={'billGeorge'} label={'Who is Bill George'} className={'bg-blue-600'}/>
          <Tab key={'franchises'} label={'Franchises'} className={'bg-blue-600'}/>
          <Tab key={'executives'} label={'Executives'} className={'bg-blue-600'}/>
          <Tab key={'superBowlWinners'} label={'Super Bowl Winners'} className={'bg-blue-600'}/>
        </Tabs>
      </Box>
      <TabComponent value={value} index={0}>
        <AboutHistory/>
      </TabComponent>
      <TabComponent value={value} index={1}>
        <BillGeorge/>
      </TabComponent>
      <TabComponent value={value} index={2}>
        <Organizations/>
      </TabComponent>
      <TabComponent value={value} index={3}>
        <ExecutiveList/>
      </TabComponent>
      <TabComponent value={value} index={4}>
        <SuperBowlWinners/>
      </TabComponent>
    </Layout>
  )
}

export default AboutPage
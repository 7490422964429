import React, {FC, useState, useContext} from 'react'
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import Layout from '../Layout/Layout'
import { useAllOrganizations } from '../hooks/useAllOrganizations'
import AdminUpdateScoreWidget from './AdminUpdateScoreWidget'
import {parseOrganizationId} from '../utils/utils'

import { AuthContext } from './authContext' 

const AdminUpdateScorePage: FC = () => {

  const auth = useContext(AuthContext)

  const [selectedWeek, setTargetWeek] = useState<string>('1')
  const [selectedOrg, setTargetOrg] = useState<string>(auth.organizationId)
  const {data: organizations} = useAllOrganizations()
  
  const handleWeekChange = (event: SelectChangeEvent) => {
    setTargetWeek(event.target.value)
  }

  const handleOrgChange = (event: SelectChangeEvent) => {
    setTargetOrg(event.target.value)
  }

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3} rowSpacing={3} columnSpacing={3} padding={3}>
        <Grid item md={3} xs={0}/>
        <Grid item container md={6} xs={12}>
          <Grid item container md={6} xs={12} justifyContent="center">
            <FormControl sx={{m: 1, minWidth: 150}} size="small">
              <InputLabel id="demo-select-small">Organization</InputLabel>
              <Select
                label="Organization"
                onChange={handleOrgChange}
                value={selectedOrg}
                >
                  {(organizations || [])
                    .sort((a, b) => String(a.organizationId).localeCompare(b.organizationId))
                    .map((org) => (
                    <MenuItem key={org.organizationId} value={org.organizationId}>
                      {parseOrganizationId(org.organizationId)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item container md={6} xs={12} justifyContent="center">
            <FormControl sx={{m: 1, minWidth: 150}} size="small">
              <InputLabel id="demo-select-small">Date</InputLabel>
              <Select
                label="Date"
                onChange={handleWeekChange}
                value={selectedWeek}
                >
                  {globalThis.app.WEEKS.map((week) => (
                    <MenuItem key={week.value} value={week.value}>
                      {week.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={3} xs={0}/>
        <Grid item lg={4} md={6} xs={12} rowSpacing={3}>
          <AdminUpdateScoreWidget organizationId={selectedOrg} week={selectedWeek} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AdminUpdateScorePage

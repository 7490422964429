import {FC} from 'react'
import {useParams} from 'react-router-dom'
import Layout from './Layout/Layout'
import {lowerCaseAndUnderscore, getImageForDivision} from './utils/utils'
import { Avatar, Card, CardActionArea, CardHeader, Grid, Typography } from '@mui/material'
import ScheduleWidget from './widgets/ScheduleWidget'
import StandingsWidget from './widgets/StandingsWidget'
import {useMultipleGamesByDivision} from './hooks/multipleGameHooks'

const DivisionPage: FC = () => {

  const {divisionName} = useParams()

  const lowerDivision = (divisionName) ? lowerCaseAndUnderscore(divisionName) : 'Undefined Division'

  console.log(`divisionId: ${lowerDivision}`)
  
  const divisionNoDashes = (divisionName) ? divisionName.replace('-',' ') : 'Undefined Division'
  
  const {data: divisionGames} = useMultipleGamesByDivision(globalThis.app.YEAR, lowerDivision)

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant={'outlined'}>
            <CardActionArea>
              <CardHeader
                avatar={
                  <Avatar src={getImageForDivision(lowerDivision)}/>
                }
                title={
                  <Typography variant="h5" component="div" noWrap display="inline">
                    {`${divisionNoDashes} Page`}
                  </Typography>
                }
              />
            </CardActionArea>
          </Card>
        </Grid>          
        <Grid item lg={7} md={6} xs={12}>
          <ScheduleWidget games={divisionGames || []}/>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <StandingsWidget year={globalThis.app.YEAR} divisionId={lowerDivision}/>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default DivisionPage
import {useMultipleGames} from './useMultipleGames'
import {useMultipleGamesByOrganizationId} from './useMultipleGamesByOrganizationId'
import {useMultipleGamesByTeamId} from './useMultipleGamesByTeamId'
import {lowerCaseAndUnderscore} from '../utils/utils'

const useMultipleGamesByTeam = (year: string, divisionId: string, teamName: string) => {
  
  return useMultipleGamesByTeamId({
    year,
    teamId: `${lowerCaseAndUnderscore(divisionId)}-${lowerCaseAndUnderscore(teamName)}`
  })
}

const useMultipleGamesByWeek = (year: string, week: string) => {
  return useMultipleGames({year, week})
}

const useMultipleGamesByOrganization = (year: string, organizationId: string, overrideEnabled: boolean = true) => {
  return useMultipleGamesByOrganizationId({year, organizationId, overrideEnabled})
}

const useMultipleGamesByDivision = (year: string, divisionId: string, week?: string, ) => {
  return useMultipleGames({year, divisionId, week})
}

const useMultipleGamesByFieldId = (year: string, week: string, fieldId: string) => {
  return useMultipleGames({year, week, fieldId})
}

export {
  useMultipleGamesByTeam,
  useMultipleGamesByWeek,
  useMultipleGamesByOrganization,
  useMultipleGamesByDivision,
  useMultipleGamesByFieldId,
}
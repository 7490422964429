import {ApiGame} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

interface IUseGame {
  year: string
  gameId: string
}

const getGame = async ({year, gameId}: IUseGame) => {
  console.log('FETCH: Fetching Game - ', year, gameId)
  const {data} = await apiInterface.get<ApiGame>('getGame', {
    params: {
      year,
      gameId
    }
  })
  
  return data
}



//export const updateGame = async (updateGameInfo: ApiGame, fieldRep: String, fieldRepPswd: String) => {
export const updateGame = async (updateGameInfo: ApiGame) => {
    console.log('UPDATE: Updating Game - ', updateGameInfo)
  const {
    homeTeamScore,
    awayTeamScore,
    gameId,
    year
  } = updateGameInfo

  // TODO: we should pass up the JWT token in the request and check in the server valid 'group' and 'org'
  
  const postData = JSON.stringify({
    homeTeamScore,
    awayTeamScore,
    gameId,
    year //,
    //userName: fieldRep,
    //password: fieldRepPswd
  })
  const {data} = await apiInterface.post<{ success: boolean, message: string }>('updateGameScore', postData)
  
  return data
}

export const updateGameSchedule = async (updateGameScheduleInfo: ApiGame, fieldRep: String, fieldRepPswd: String) => {
  console.log('UPDATE: Updating Game Schedule - ', updateGameScheduleInfo)
  const {
    year,
    gameId,
    awayOrganizationId,
    awayTeamName,
    divisionId,
    fieldId,
    gameDate,
    gameTime,
    homeOrganizationId,
    homeTeamName,
    week
  } = updateGameScheduleInfo

  const postData = JSON.stringify({
    year,
    gameId,
    awayOrganizationId,
    awayTeamName,
    divisionId,
    fieldId,
    gameDate,
    gameTime,
    homeOrganizationId,
    homeTeamName,
    week,
    userName: fieldRep,
    password: fieldRepPswd
  })
  const {data} = await apiInterface.post<{ success: boolean, message: string }>('updateGameSchedule', postData)
  
  return data
}



export const useInvalidateGame = ({
                                    year,
                                    gameId
                                  }: IUseGame) => {
  
  const queryValues = [
    queryKeys.GET_GAME,
    year,
    gameId
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateGame = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateGame
  }
}

export const useGame = ({
                          year,
                          gameId,
                        }: IUseGame) => {
  const {queryValues} = useInvalidateGame({year, gameId})
  
  return useQuery<ApiGame>(
    queryValues,
    () => getGame({year, gameId}),
    {
      ...queryOptions,
      enabled: !!year && !!gameId,
    }
  )
}





import React, {FC} from 'react'
import {useParams} from 'react-router-dom'
import TeamListWidget from './widgets/TeamListWidget'
import {sortTeamsByDivision} from './utils/utils'
import {Card, Grid} from '@mui/material'
import {useMultipleTeamsByOrganization} from './hooks/multipleTeamHooks'

const TeamListPage: FC = () => {
  const { organizationName } = useParams()
  
  const {data: organizationTeams} = useMultipleTeamsByOrganization(globalThis.app.YEAR, organizationName)
  
  if (!organizationName) {
    return null
  }

  return (
    <>
      <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'} style={{ background: '#15508c', color: 'white' }}>
        <Grid container spacing={1} className={'flex justify-center items-center px-12 py-4 text-xl'}>
          <Grid item lg={12} md={12} xs={12} className={'flex justify-center items-center font-bold'}>
            <span>{`${organizationName} Teams`}</span>
          </Grid>
        </Grid>
      </Card>
      <TeamListWidget teamInfo={sortTeamsByDivision(organizationTeams || [])}/>
    </>
  )
}

export default TeamListPage
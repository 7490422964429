import React, {FC, useEffect, useState} from 'react'
import Card from '@mui/material/Card/Card'
import {Box, Button, CardContent, Grid, TextField, Typography} from '@mui/material'
import Layout from '../Layout/Layout'
import {sortTeamsByDivision} from '../utils/utils'
import { useOrganization, updatetOrganization } from '../hooks/useOrganization'
import { useMultipleTeamsByOrganization } from '../hooks/multipleTeamHooks'
import TeamListWidget from '../widgets/TeamListWidget'
import {ApiOrganization} from '../types/apiTypes'

const AdminEditOrganiztionPage: FC = () => {

  const {data: organizationInfo} = useOrganization({organizationId: `downers_grove`, })
  const {data: organizationTeams} = useMultipleTeamsByOrganization(globalThis.app.YEAR, 'downers_grove')
  
  const [organizationId, setOrganizationId] = useState<string>('')
  const [president, setPresident] = useState<string>('')
  const [organizationName, setOrganizationName] = useState<string>('')
  const [nickname, setNickname] = useState('')
  const [websiteUrl, setOrgUrl] = useState<string>('')
  const [bgyflReps, setBgyflReps] = useState<string>('')
  const [cheerReps, setCheerReps] = useState<string>('')

  useEffect(() => {
    if (organizationInfo) {
      const {organizationId, president, organizationName, nickname, websiteUrl, bgyflReps, cheerReps} = organizationInfo
      setOrganizationId(organizationId)
      setPresident(president)
      setOrganizationName(organizationName)
      setNickname(nickname || '')
      setOrgUrl(websiteUrl)
      setBgyflReps(bgyflReps)
      setCheerReps(cheerReps || '')
    }
  }, [organizationInfo])
  
  const handleChangeWebSiteUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrgUrl(event.target.value)
  }
  const handleChangeBgyflReps = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBgyflReps(event.target.value)
  }
  const handleChangeCheerReps = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheerReps(event.target.value)
  }
    
  const submitOrgForm = () => {
    let orgSubmitData: ApiOrganization = {
      organizationId: organizationId,
      president: president,
      organizationName: organizationName, 
      websiteUrl: websiteUrl, 
      nickname: nickname, 
      bgyflReps: bgyflReps, 
      cheerReps: cheerReps, 
    }
    console.log(orgSubmitData)

    let returnValue = updatetOrganization(orgSubmitData)
    console.log('returnValue: ' + returnValue)
  }

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant={'outlined'}>
            {'Edit Organization'}
          </Card>
        </Grid>          
        <Grid item lg={4} md={6} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={submitOrgForm}
              >
                <Typography variant="h5" component="div">
                  {organizationName}
                </Typography>
                <TextField id="president" disabled margin='normal' fullWidth size="small" label="President" value={president} />
                <TextField id="nickname" disabled margin='normal' fullWidth size="small" label="Nickname" value={nickname}/>
                <TextField id="website" margin='normal' fullWidth size="small" required label="Organization URL" value={websiteUrl} onChange={handleChangeWebSiteUrl}/>
                {
                  organizationInfo?.bgyflReps &&
                    <TextField id="bgyfl_reps" margin='normal' fullWidth size="small" required label="BGYFL Reps" value={bgyflReps} onChange={handleChangeBgyflReps} />
                }
                {
                  organizationInfo?.cheerReps &&
                    <TextField id="cheer_reps" margin='normal' fullWidth size="small" label="Cheer Reps" value={cheerReps} onChange={handleChangeCheerReps} />
                }
                <TextField id="orgId" disabled margin='normal' fullWidth size="small" label="Organization Id" value={organizationId}/>
                <Button id="orgSubmitButton" variant='outlined' onClick={submitOrgForm}>Save</Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <TeamListWidget teamInfo={sortTeamsByDivision(organizationTeams || [])} showCoachInfo={true} isEditable={true}/>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AdminEditOrganiztionPage


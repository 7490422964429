import React, {FC, useState} from 'react'
import Card from '@mui/material/Card/Card'
import {Box, Button, Grid} from '@mui/material'
import Layout from '../Layout/Layout'
import {capitalizeWord, sortTeamsByTeamName} from '../utils/utils'
import {useMultipleTeamsByDivision} from '../hooks/multipleTeamHooks'
import TeamListWidget from '../widgets/TeamListWidget'
import {generateMatchMatrix, scoreMatchMatrix} from '../PracticePage/SchedulingTestingTab/schedulingUtils'
import {ApiTeam} from '../types/apiTypes'

const AdminCreateSchedulePage: FC = () => {
  
  const {data: divisionTeams} = useMultipleTeamsByDivision(globalThis.app.YEAR, '10u_d1_gold')
  const [matchMatrix, setMatchMatrix] = useState<ApiTeam[][][]>()
  
  const handleCreateSchedule = () => {
    if (divisionTeams) {
      let minMatchMatrix = generateMatchMatrix(divisionTeams)
      let [minTotal, minScores] = scoreMatchMatrix(minMatchMatrix)
      for (let i = 0; i < 10000; i++) {
        const matchMatrix = generateMatchMatrix(divisionTeams)
        const [total, scores] = scoreMatchMatrix(matchMatrix)
        if (total < minTotal) {
          minTotal = total
          minScores = scores
          minMatchMatrix = matchMatrix
        }
      }
      
      console.log('==> Final min total: ', minTotal)
      console.log('==> Final min scores: ', minScores)
      console.log('==> Final min matchMatrix: ', minMatchMatrix)
      setMatchMatrix(minMatchMatrix)
    }
  }
  
  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant={'outlined'}>
            {'Create Schedule - need a drop down with list of divisions'}
          </Card>
        </Grid>
        <Grid item lg={7} md={6} xs={12}>
          <TeamListWidget teamInfo={sortTeamsByTeamName(divisionTeams || [])}/>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Button
              variant="outlined"
              onClick={handleCreateSchedule}
            >
              {'Create Schedule'}
            </Button>
          </Box>
          {
            matchMatrix && (
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                {
                  matchMatrix.map((week, weekIndex) => {
                    return (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <span className={'font-bold text-xl'}>{`Week ${weekIndex + 1}`}</span>
                        {
                          week.map((match) => {
                            const homeTeam = match[0]?.teamName || 'BYE'
                            const awayTeam = match[1]?.teamName || 'BYE'
                            const parsedHome = homeTeam.split('_').map(capitalizeWord).join(' ')
                            const parsedAway = awayTeam.split('_').map(capitalizeWord).join(' ')
                            return (
                              <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                              >
                                <span>
                                  {`${parsedHome} vs. ${parsedAway}`}
                                </span>
                              </Box>
                            )
                          })
                        }
                      </Box>
                    )
                  })
                }
              </Box>
            )
          }
        </Grid>
      </Grid>
    </Layout>
  )
}

export default AdminCreateSchedulePage
import React, {FC, PropsWithChildren} from 'react'
import {Box} from "@mui/material";

type TabComponentProps = {
  value: number
  index: number
}

const TabComponent: FC<PropsWithChildren<TabComponentProps>> = ({
  value,
  index,
  children
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabComponent
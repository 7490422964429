import React, {FC, useContext} from 'react'
import Card from '@mui/material/Card/Card'
import {CardContent, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import Layout from './Layout/Layout'
import {AuthContext, AuthStatus} from '../Pages/Admin/authContext'


const HomePage: FC = () => {
  
  const {authStatus} = useContext(AuthContext)
  const auth = useContext(AuthContext)
  console.log(`auth: ${JSON.stringify(auth)}`)
  const authUserOrgId = auth.organizationId
  const isAdmin = auth.groups.includes('admins')
  
  const generateLinkTile = (path: string, label: string): JSX.Element => {
    return (
      <Link to={path} className={'px-8'}>
        <Card variant={'outlined'}>
          <CardContent>
            <div className={'flex flex-row'}>
              <div className={'float: left'}>
                <Typography variant="h5" component="div">
                  {label}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </Link>
    )
  }
  
  return (
    <Layout>
      <div className={'flex flex-row items-center justify-center m-8'}>
        {generateLinkTile('/organizations', 'Organizations')}
        {generateLinkTile('/divisions', 'Divisions')}
        {
          authStatus === AuthStatus.SignedIn &&
          generateLinkTile('/admin/updateScores', 'Update Scores')
        }
        {
          authStatus === AuthStatus.SignedIn && isAdmin &&
          generateLinkTile('/admin/weeklyScoreReport', 'Weekly Score Report')
        }
      </div>
    </Layout>
  )
}

export default HomePage
import {ApiOrganization} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

const getAllOrganizations = async () => {
  console.log('FETCH: Fetching All Organizations')
  const {data} = await apiInterface.get<{ organizations: ApiOrganization[] }>('getOrganizations')
  return data.organizations
}

export const useInvalidateAllOrganizations = () => {
  const queryValues = [queryKeys.GET_ALL_ORGANIZATIONS]
  
  const queryClient = useQueryClient()
  
  const invalidateAllOrganizations = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateAllOrganizations
  }
}

export const useAllOrganizations = () => {
  const {queryValues} = useInvalidateAllOrganizations()
  
  return useQuery<ApiOrganization[]>(
    queryValues,
    () => getAllOrganizations(),
    {
      ...queryOptions,
      enabled: true,
    }
  )
}




import React, {FC} from 'react'
import Header from './Header'

type PropsWithChildren = {
  children: React.ReactNode
}

const Layout: FC<PropsWithChildren> = ({children}) => {
  return (
    <div className={'flex flex-col h-screen'}>
      <Header/>
      <div className={'h-7/8'}>
        {children}
      </div>
    </div>
  )
}

export default Layout

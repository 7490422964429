import React, {FC} from 'react'
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Layout from './Layout/Layout'

const DivisionList: FC = () => {
  
  const ageGroups = [
    'Varsity',
    'JV',
    '11U',
    '10U',
    '9U',
  ]
  
  const divisions = [
    'Gold D1',
    'Gold D2',
    'Silver'
  ]
  
  let totalDivisions = ageGroups.flatMap(ageGroup => {
    return divisions.map(division => {
      return `${ageGroup} ${division}`
    })
  })
  
  totalDivisions = [...totalDivisions, '8U', 'Flag']
  
  return (
    <Layout>
      <div className={'p-4'}>
        <Grid container justifyContent="space-around" spacing={3}>
          {
            totalDivisions.map((division, index) => {
              const divisionString = division.replace(' ', '-')
              return (
                <Grid item lg={4} md={6} xs={12} key={index}>
                    <Card key={index} variant={'outlined'}>
                      <CardActionArea component={Link} to={`/divisions/${divisionString}`}>
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {division}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </Layout>
  )
}

export default DivisionList


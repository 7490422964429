import React, {FC, useState, useEffect} from 'react'
import {Box, Button, Card, CardContent, TextField, Typography} from '@mui/material'
import {ApiGame} from '../types/apiTypes'
import {updateGameSchedule} from '../hooks/useGame'

type AdminEditGameDetailsProps = {
  gameInfoToEdit: ApiGame,
}

const AdminEditGameDetails: FC<AdminEditGameDetailsProps> = ({
  gameInfoToEdit,
                                                             }) => {
  
  const [updatedGameInfo, setUpdatedGameInfo] = useState<ApiGame>(gameInfoToEdit)

  useEffect(() => {
    setUpdatedGameInfo(gameInfoToEdit)
  }, [gameInfoToEdit])
  
  const handleChangeGameInfoField = (fieldName: string, value: string) => {
    setUpdatedGameInfo({...updatedGameInfo, [fieldName]: value})
  }
  
  const submitEditScheduleForm = () => {
    let outputString = 'Saving Game Info with gameInfo:'
    for (const [key, value] of Object.entries(updatedGameInfo)) {
      outputString += `\n\t${key}: ${value}`;
    }
    console.log(outputString)
    // gameInfoToEdit - has the original gameId (that will be deleted) and all updated information to create the new game record
    updateGameSchedule(updatedGameInfo, "superAdmin", "bgyflForLife")
  }
  
  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={submitEditScheduleForm}
        >
          <Typography variant="h5" component="div">
            {'GAME INFO'}
          </Typography>
          <TextField id="gameId" disabled margin="normal" fullWidth size="small" label="GameId"
                     value={updatedGameInfo.gameId}/>
          <TextField id="gameDate" margin="normal" fullWidth size="small" label="Game Date"
                     value={updatedGameInfo.gameDate}
                     onChange={(event) => handleChangeGameInfoField('gameDate', event.target.value)}/>
          <TextField id="gameTime" margin="normal" fullWidth size="small" label="Game Time"
                     value={updatedGameInfo.gameTime}
                     onChange={(event) => handleChangeGameInfoField('gameTime', event.target.value)}/>
          <TextField id="fieldId" margin="normal" fullWidth size="small" required label="Field ID"
                     value={updatedGameInfo.fieldId}
                     onChange={(event) => handleChangeGameInfoField('fieldId', event.target.value)}/>
          <TextField id="homeTeamName" disabled margin="normal" fullWidth size="small" required label="Home Team"
                     value={updatedGameInfo.homeTeamName}/>
          <TextField id="awayTeamName" disabled margin="normal" fullWidth size="small" label="Away Team"
                     value={updatedGameInfo.awayTeamName}/>
          <Button id="orgSubmitButton" variant="outlined" onClick={submitEditScheduleForm}>Save</Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AdminEditGameDetails          
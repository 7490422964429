import axios from 'axios'
import {API_BASE_URL} from './api'

const queryKeys = {
  GET_TEAM: 'getTeam',
  GET_MULTIPLE_TEAMS: 'getTeams',
  GET_GAME: 'getGame',
  GET_MULTIPLE_GAMES: 'getGames',
  GET_MULTIPLE_GAMES_BY_ORGANIZATION_ID: 'getGamesByOrganizationId',
  GET_ORGANIZATION: 'getOrganization',
  GET_ALL_ORGANIZATIONS: 'getAllOrganizations',
  GET_FIELD: 'getField',
  UPDATE_ORGANIZATION: 'updateOrganization',
  HEALTH_CHECK_POST: 'healthCheckPost',
}

const queryOptions = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false
}

const apiInterface = axios.create({
  baseURL: API_BASE_URL
})

export { queryKeys, queryOptions, apiInterface }
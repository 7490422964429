import {ApiGame} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

interface IUseMultipleGames {
  year: string
  week?: string
  teamId?: string
  gameDate?: string
  organizationId?: string
  divisionId?: string
  teamName?: string
  fieldId?: string
  overrideEnabled?: boolean
}

const getMultipleGames = async ({
                                  year,
                                  teamId,
                                  week,
                                  gameDate,
                                  organizationId,
                                  divisionId,
                                  teamName,
                                  fieldId,
                                }: IUseMultipleGames) => {
  console.log('FETCH: Fetching Multiple Games - ', year, teamId, week, gameDate, organizationId, divisionId, teamName, fieldId)
  const {data} = await apiInterface.get<{ games: ApiGame[] }>('getGames', {
    params: {
      year,
      week,
      teamId,
      gameDate,
      organizationId,
      divisionId,
      teamName,
      fieldId,
    }
  })
  
  return data.games
}

export const useInvalidateMultipleGames = ({
                                             year,
                                             teamId,
                                             week,
                                             gameDate,
                                             organizationId,
                                             divisionId,
                                             teamName,
                                             fieldId,
                                           }: IUseMultipleGames) => {
  const queryValues = [
    queryKeys.GET_MULTIPLE_GAMES,
    year,
    teamId,
    week,
    gameDate,
    organizationId,
    divisionId,
    teamName,
    fieldId,
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateMultipleGames = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateMultipleGames
  }
}

export const useMultipleGames = ({
                                   year,
                                   teamId,
                                   week,
                                   gameDate,
                                   organizationId,
                                   divisionId,
                                   teamName,
                                   fieldId,
                                   overrideEnabled = true
                                 }: IUseMultipleGames) => {
  const {queryValues} = useInvalidateMultipleGames({
    year,
    teamId,
    week,
    gameDate,
    organizationId,
    divisionId,
    teamName,
    fieldId,
  })
  
  return useQuery<ApiGame[]>(
    queryValues,
    () => getMultipleGames({year, teamId, week, gameDate, organizationId, divisionId, teamName, fieldId}),
    {
      ...queryOptions,
      enabled: overrideEnabled && !!year,
    }
  )
}


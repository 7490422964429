import React, {FC, useContext} from 'react'
import {useParams} from 'react-router-dom'
import { Avatar, Card, CardHeader, Grid, Typography } from '@mui/material'
import Layout from './Layout/Layout'
import TeamListWidget from './widgets/TeamListWidget'
import ScheduleWidget from './widgets/ScheduleWidget'
import {parseOrganizationId, sortTeamsByDivision, getImage} from './utils/utils'
import {useMultipleTeamsByOrganization} from './hooks/multipleTeamHooks'
import {useMultipleGamesByOrganization} from './hooks/multipleGameHooks'
import { useOrganization } from './hooks/useOrganization'
import {AuthContext} from './Admin/authContext'

const OrganizationPage: FC = () => {
  const {organizationId} = useParams()
  const auth = useContext(AuthContext)
  //const authUserOrgId = auth.organizationId
  //const isAdmin = auth.groups.includes('admins')
  
  const headerRowClassNames = 'flex justify-center items-center font-bold'
  const {data: organizationInfo} = useOrganization({organizationId: organizationId!})
  const {data: organizationTeams} = useMultipleTeamsByOrganization(globalThis.app.YEAR, organizationId!)

  // if logged in - show a preview
  //let year_to_show = (isAdmin) ? "2024" : globalThis.app.YEAR;
  //console.log(`year_to_show: ${year_to_show}`);
  //const {data: organizationGames} = useMultipleGamesByOrganization(year_to_show, organizationId!)
  const {data: organizationGames} = useMultipleGamesByOrganization(globalThis.app.YEAR, organizationId!)

  //let website = ""
  let nickname = ""
  if (organizationInfo){
    //website = organizationInfo!['websiteUrl']
    nickname = organizationInfo!['nickname']!
  }

// need some help here.  
// 1. i get org data back into organizationData, but don't know how to use it
// 2. use some of those details 
//      - CardAction to be a link to the organization websiteUrl
//      - add the mascot name to the header

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant={'outlined'}>
            <a href={organizationId} target='_blank' rel="noreferrer" >
              <CardHeader
                avatar={
                  <Avatar src={getImage(organizationId)} />
                }
                title={
                  <Typography variant="h5" component="div" noWrap display="inline">
                    {`${parseOrganizationId(organizationId)}`}
                  </Typography>
                }
                subheader={nickname}
              />
            </a>
          </Card>
        </Grid>
        <Grid item lg={7} md={6} xs={12}>
            <ScheduleWidget
              games={organizationGames || []}
              organizationId={organizationId}
            />
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
            <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'} style={{ background: '#15508c', color: 'white' }}>
              <Grid container spacing={1} className={'flex justify-center items-center px-12 py-4 text-xl'}>
                <Grid item lg={12} md={12} xs={12} className={headerRowClassNames}>
                  <span>{`Teams`}</span>  
                </Grid>
              </Grid>
            </Card>
            <TeamListWidget teamInfo={sortTeamsByDivision(organizationTeams || [])}/>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default OrganizationPage

function userContext(AuthContext: any) {
  throw new Error('Function not implemented.')
}

import React, {FC} from 'react'
import TeamCardLayout from './TeamCardLayout'
import {Grid} from '@mui/material'
import {parseTeamName} from '../utils/utils'
import {ApiTeam} from '../types/apiTypes'

type StandingTeamCardProps = {
  teamInfo: ApiTeam,
  rank?: number
}

const StandingTeamCard: FC<StandingTeamCardProps> = ({teamInfo, rank}) => {
  const {year, divisionId, teamName} = teamInfo
  const showRecord = !["flag", "8u"].includes(divisionId)
  const yearDivisionTeamName = `${year}-${divisionId}-${teamName}`
  
  const rowClassNamesCenter = 'flex justify-center items-center'
  const rowClassNamesLeft = 'flex justify-left items-left'
  
  return (
    <TeamCardLayout yearDivisionTeamName={yearDivisionTeamName}>
      <Grid container spacing={1} className={'flex justify-center items-center px-4'}>
        <Grid item lg={3} md={3} xs={3} className={rowClassNamesCenter}>
          <span>{rank}</span>
        </Grid>
        <Grid item lg={4} md={4} xs={4} className={rowClassNamesLeft}>
          <span>{parseTeamName(teamName)}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
          <span>{showRecord?teamInfo.wins:"-"}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
          <span>{showRecord?teamInfo.losses:"-"}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
          <span>{showRecord?teamInfo.ties:"-"}</span>
        </Grid>
        <Grid item lg={2} md={2} xs={2} className={rowClassNamesCenter}>
          <span>{showRecord?teamInfo.playoffPoints:"-"}</span>
        </Grid>
      </Grid>
    </TeamCardLayout>
  )
}

export default StandingTeamCard
import React, {FC} from 'react'
import ScheduleWidget from './widgets/ScheduleWidget'
import {useMultipleGamesByTeam} from './hooks/multipleGameHooks'

type TeamScheduleProps = {
  year: string
  divisionId: string
  teamName: string
  organizationId?: string
}

const TeamSchedule: FC<TeamScheduleProps> = ({year, divisionId, teamName, organizationId}) => {
  const {data: games} = useMultipleGamesByTeam(globalThis.app.YEAR, divisionId, teamName)

  return (
    <ScheduleWidget
      games={games || []}
      organizationId={organizationId}
    />
  )
}

export default TeamSchedule
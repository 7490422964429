import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'
import {ApiTeam} from '../types/apiTypes'

interface IUseTeam {
  year: string
  divisionId: string
  teamName: string
}

const getTeam = async ({year, divisionId, teamName}: IUseTeam) => {
  console.log('FETCH: Fetching Team - ', year, divisionId, teamName)
  const {data} = await apiInterface.get<{team: ApiTeam}>('getTeam', {
    params: {
      year,
      teamId: `${divisionId}-${teamName}`
    }
  })
  return data.team
}

export const updateTeam = async (teamInfo: ApiTeam) => {
  console.log('UPDATING TEAM:  - ', teamInfo)
  const {data} = await apiInterface.post<ApiTeam>('updateTeam', 
    JSON.stringify(teamInfo)
  )
  
  return data
}

export const useInvalidateTeam = ({
                                    divisionId,
                                    teamName,
                                    year
                                  }: IUseTeam) => {
  
  const queryValues = [
    queryKeys.GET_TEAM,
    year,
    divisionId,
    teamName,
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateTeam = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateTeam
  }
}

export const useTeam = ({
                          year,
                          divisionId,
                          teamName,
                        }: IUseTeam) => {
  const {queryValues} = useInvalidateTeam({year, divisionId, teamName})
  
  return useQuery<ApiTeam>(
    queryValues,
    () => getTeam({year, divisionId, teamName}),
    {
      ...queryOptions,
      enabled: !!year && !!divisionId && !!teamName,
    }
  )
}
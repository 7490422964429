import {ApiTeam} from '../../types/apiTypes'

/*
* Ideas come from https://stackoverflow.com/questions/6648512/scheduling-algorithm-for-a-round-robin-tournament
*
* Minified because it was a little bit long to read
*
* Rotate the array (except the first team) for each week, then pair off correctly
* */

const singleRound = (t: number, w: number) => {
  const n = Array.from({length: t}, (_, i) => i)
  return [n[0], ...n.slice(w + 1), ...n.slice(1, w + 1)]
}


export const generateMatchMatrix = (x: ApiTeam[]): ApiTeam[][][] => {
  const s = shuffle(x)
  const t = s.length + (s.length % 2)
  const m = Array.from({length: t - 1}, (_, i) => singleRound(t, i))
  let o = m.map((r) =>
    Array.from(
      {length: t / 2},
      (_, i) => {
        if (Math.random() < 0.5) {
          return [s[r[i]], s[r[t - i - 1]]]
        } else {
          return [s[r[t - i - 1]], s[r[i]]]
        }
      }
    ))
  return shuffle(o).slice(0, 8)
}

// Knuth Shuffle - https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
export const shuffle = <T extends any[]>(t: T): T => {
  let c = t.length,  r;
  
  while (c !== 0) {
    r = Math.floor(Math.random() * c);
    c--;
    [t[c], t[r]] = [t[r], t[c]];
  }
  
  return t;
}

export const scoreMatchMatrix = (m: ApiTeam[][][]): [number, {[key: string]: number}] => {
  // Flatten down to a single array of games, remove byes
  const games = m.reduce((acc, cur) => [...acc, ...cur], [])
  const noByes = games.filter(([a, b]) => a && b)
  
  
  const uniqueTeams = new Set(
    noByes.reduce(
      (
        acc, [a, b]) => [...acc, a.divisionTeamName, b.divisionTeamName],
      [] as string[]
    )
  )
  
  // Map to each team home and away
  const teamHomeAwayDifferential = noByes.reduce((acc, [a, b]) => {
    acc[a.divisionTeamName] += 1
    acc[b.divisionTeamName] -= 1
    return acc
  }, Array.from(uniqueTeams).reduce((acc, cur) => {
    acc[cur] = 0
    return acc
  }, {} as {[key: string]: number})) as {[key: string]: number}
  
  const squaredResult = Object.keys(teamHomeAwayDifferential).reduce((acc, cur) => {
    acc[cur] = teamHomeAwayDifferential[cur] ** 2
    return acc
  }, {} as {[key: string]: number})
  return [Object.values(squaredResult).reduce((acc, cur) => acc + cur, 0), squaredResult]
}
// var YEAR: string
// var YEAR_NUM: number
// var authUserOrganizationId: string

// globalThis.app.YEAR = '2022'
// globalThis.app.YEAR_NUM = 2022

declare global {
    var app: globalAppVariables;
}

interface iWeek {value: string, label: string}

type globalAppVariables = {
    YEAR: string,
    YEAR_NUM: number,
    WEEKS: iWeek[]
};

globalThis.app = {
    YEAR: '2024',
    YEAR_NUM: 2024,
    WEEKS: [
        {value: 'full', label: 'Full Schedule',},
        {value: '1', label: 'Week 1 - August 24/25',},
        {value: '2', label: 'Week 2 - August 31/1',},
        {value: '3', label: 'Week 3 - September 7/8',},
        {value: '4', label: 'Week 4 - September 14/15',},
        {value: '5', label: 'Week 5 - September 21/22',},
        {value: '6', label: 'Week 6 - September 27/28',},
        {value: '7', label: 'Week 7 - October 5/6',},
        {value: '8', label: 'Week 8 - October 12/13',},
        {value: '9', label: 'Week 9 - October 19/20',},
        {value: '10', label: 'Week 10 - Playoff Rd1 - October 26/27',},
      ]
};

Object.freeze(globalThis.app);

export {}

/*
interface iWeek {value: string, label: string}
var WEEKS: iWeek[]

globalThis.app.WEEKS = [
    {value: 'full', label: 'Full Schedule',},
    {value: '1', label: 'Week 1 - August 27/28',},
    {value: '2', label: 'Week 2 - September 3/4',},
    {value: '3', label: 'Week 3 - September 10/11',},
    {value: '4', label: 'Week 4 - September 17/18',},
    {value: '5', label: 'Week 5 - September 24/25',},
    {value: '6', label: 'Week 6 - October 1/2',},
    {value: '7', label: 'Week 7 - October 8/9',},
    {value: '8', label: 'Week 8 - October 15/16',},
    {value: '9', label: 'Week 9 - Playoff Rd1 - October 22/23',},
  ]

  */
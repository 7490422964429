import {ApiGame} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

interface IUseMultipleGamesByOrganizationId {
  year: string
  organizationId: string
  overrideEnabled?: boolean
}

const getMultipleGames = async ({
                                  year,
                                  organizationId
                                }: IUseMultipleGamesByOrganizationId) => {
  console.log('FETCH: Fetching Multiple Games - ', year, organizationId)
  const {data} = await apiInterface.get<{ games: ApiGame[] }>('getGamesByOrganizationId', {
    params: {
      year,
      organizationId
    }
  })
  
  return data.games
}

export const useInvalidateMultipleGames = ({
                                             year,
                                             organizationId
                                           }: IUseMultipleGamesByOrganizationId) => {
  const queryValues = [
    queryKeys.GET_MULTIPLE_GAMES,
    year,
    organizationId,
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateMultipleGames = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateMultipleGames
  }
}

export const useMultipleGamesByOrganizationId = ({
                                   year,
                                   organizationId,
                                   overrideEnabled = true
                                 }: IUseMultipleGamesByOrganizationId) => {
  const {queryValues} = useInvalidateMultipleGames({
    year,
    organizationId,
  })
  
  return useQuery<ApiGame[]>(
    queryValues,
    () => getMultipleGames({year, organizationId}),
    {
      ...queryOptions,
      enabled: overrideEnabled && !!year,
    }
  )
}


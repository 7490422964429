import React from 'react'
import {CardContent, Card, CardHeader, CardActionArea, Avatar, Hidden, Typography, Grid} from '@mui/material'
import {Link} from 'react-router-dom'

import {parseOrganizationId, getImage} from '../utils/utils'
import {ApiOrganization} from '../types/apiTypes'

type DisplayOrganizationProps = {
  organizationInfo: ApiOrganization
  setIsEditing?: (x: boolean) => void
}

const DisplayOrganization: React.FC<DisplayOrganizationProps> = ({
                                                                  organizationInfo,
                                                                  setIsEditing,
                                                                }) => {

  const {
    organizationId,
    president,
    bgyflReps,
    cheerReps,
    websiteUrl,
    nickname
  } = organizationInfo
  
//  const parsedOrganizationId = parseOrganizationId(organizationId)
  
  return (
    <Grid item lg={4} md={6} xs={12}>
      <Card variant={'outlined'}>
        <CardActionArea component={Link} to={`/organizations/${organizationId}`}>
          <CardHeader
            avatar={
              <Avatar src={getImage(organizationId)} />
            }
            title={
              <Typography variant="h5" component="div" noWrap display="inline">
                {parseOrganizationId(organizationId)}
              </Typography>
            }
            subheader={nickname}
          />
          <Hidden only={'xs'}>
            <CardContent >
              <Typography sx={{mb: 1.5}} color="text.secondary" gutterBottom>
                {`President: ${president}`}
              </Typography>
              <Typography sx={{mb: 1.5}} color="text.secondary">
                {`BGYFL Reps: ${bgyflReps}`}
              </Typography>
                <Typography sx={{mb: 1.5}} color="text.secondary">
                {
                  cheerReps &&
                  `Cheer Reps: ${cheerReps}`
                }
                &nbsp;
              </Typography>
              <Typography sx={{mb: 1.5}} color={'text.secondary'}>
                {websiteUrl}
              </Typography>
            </CardContent>
          </Hidden>
        </CardActionArea>          
      </Card>
    </Grid>
  )
}

export default DisplayOrganization

/**
 </div>
              {
                setIsEditing &&
                <div className={'float: right'}>
                  <IconButton aria-label={'edit'} onClick={() => setIsEditing(true)}>
                    <EditTwoToneIcon/>
                  </IconButton>
                </div>
              }
            </div>

 */
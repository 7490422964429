import { Paper, Typography } from '@mui/material'
import React, {FC} from 'react'
import {Executive} from '../types/types'

type ExecutiveCardProps = {
  executiveInfo: Executive
}

const ExecutiveCard: FC<ExecutiveCardProps> = ({executiveInfo}) => {
  const {name, title} = executiveInfo
  return (
    <div className={'flex items-center justify-center pb-8'}>
      <Paper elevation={2} className={'w-screen'}>
        <Typography m={2} variant={'h5'}>
          {name}
        </Typography>
        <Typography m={2}>
          {title}
        </Typography>
      </Paper>
    </div>
  )
}

export default ExecutiveCard
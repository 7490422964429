import React, {FC, useState, useEffect} from 'react'
//import Card from '@mui/material/Card/Card'
import {Grid} from '@mui/material'
import Layout from '../Layout/Layout'
//import { useMultipleTeamsByOrganization } from 'hooks/multipleTeamHooks'
import AdminSelectOrganizationWidget from './AdminSelectOrganizationWidget'
//import {updateGame, useGame} from 'hooks/useGame'
import {useGame} from '../hooks/useGame'
//import {ApiGame} from 'types/apiTypes'
import AdminEditGameDetails from './AdminEditGameDetails'
import ScheduleWidget from '../widgets/ScheduleWidget'
import {useMultipleGamesByOrganization} from '../hooks/multipleGameHooks'
import {ApiGame} from '../types/apiTypes'


const AdminEditSchedulePage: FC = () => {
  const[organizationId, setOrganizationId] = useState('')
  const {data: gameInfo} = useGame({year: globalThis.app.YEAR, gameId: `downers_grove`})
   
  const [selectedGameInfo, setSelectedGameInfo] = useState<ApiGame>()
  const {data: organizationGames} = useMultipleGamesByOrganization(globalThis.app.YEAR, organizationId)

  useEffect(() => {
    setSelectedGameInfo(gameInfo)
  }, [gameInfo])
  
  const selectGameInfoHandler = (gameInfo: ApiGame) => {
    console.log('setting setSelectedGameInfo to:')
    console.log(gameInfo)
    setSelectedGameInfo(gameInfo)
  }

  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12} >
            {'Select the organization: '}
            <AdminSelectOrganizationWidget selectedOrg={organizationId} selectOrgHandler={setOrganizationId} />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {  
            organizationId && 
            <ScheduleWidget
              games={organizationGames || []}
              organizationId={organizationId}
              setGameInfo={selectGameInfoHandler}
              inEditMode={true}
            />
          }
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          {
            selectedGameInfo &&
            <AdminEditGameDetails gameInfoToEdit={selectedGameInfo} />
          }
        </Grid>
      </Grid>
    </Layout>
  ) 
}

export default AdminEditSchedulePage
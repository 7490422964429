import React, {Dispatch, FC, SetStateAction} from 'react'
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {parseOrganizationId} from '../utils/utils'
import {useAllOrganizations} from '../hooks/useAllOrganizations'

type AdminSelectOrganizationWidgetProps = {
  selectedOrg: string
  selectOrgHandler: Dispatch<SetStateAction<string>>
}
const AdminSelectOrganizationWidget: FC<AdminSelectOrganizationWidgetProps> = ({
                                                                                 selectedOrg,
                                                                                 selectOrgHandler
}) => {
  
  //const [selectedOrg, setTargetOrg] = useState<string>('')
  const {data: organizations} = useAllOrganizations()
  
  return (
    <>
      <FormControl sx={{m: 1, minWidth: 150}} size="small">
        <InputLabel id="demo-select-small">Organization</InputLabel>
        <Select
          label="Organization"
          onChange={e => selectOrgHandler(e.target.value)}
          value={selectedOrg}
        >
          {(organizations || [])
            .sort((a, b) => String(a.organizationId).localeCompare(b.organizationId))
            .map(({organizationId}) => (
              <MenuItem key={organizationId} value={organizationId}>
                {parseOrganizationId(organizationId)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}

export default AdminSelectOrganizationWidget



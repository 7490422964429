import React, {FC} from 'react'

const AboutHistory: FC = () => {
  
  const blockStatement = (text: string): JSX.Element => {
    return (
      <div className={'flex flex-row items-center pb-4'}>
        <span className={'text-l'}>{text}</span>
      </div>
    )
  }
  
  return (
    <div>
      <div className={'flex flex-row justify-center items-center pb-4'}>
        <span className={'font-bold text-3xl'}>{'History of the BGYFL'}</span>
      </div>
      {blockStatement(
        `Bill Pope was a founding father of the BGYFL and its President from 1963-1975.`
      )}
      {blockStatement(
        `In 1963 Bill Pope was able to get Bill George, Joe Fortunato, and Larry Morris (the Chicago Bears first string
          linebacker corps off of what was to be the 1963 Championship team) to travel from franchise to franchise to
          pitch the new league. While all Bears players committed to make cash contributions, Bill George was the only
          one to send money so Bill Pope named our League after him. The cash contribution to start the BGYFL was $100.00.`
      )}
      {blockStatement(
       `The Chicago Suburban League was formed in 1960 and the BGYFL was created to be a “cut” league or “B” league
          for the stronger CSL. Over the next thirty years many towns morphed between the two leagues and or had teams
          in both leagues. Towns such as Northbrook, Glencoe, Glenside, Hoffman Estates, Hanover Park, Oak Park,
          Addison, Schaumburg, Glen Ellyn, Bensenville and Lombard were mainstays in the CSL. Meanwhile other
          communities began to emerge in the up and coming BGYFL such as West Chicago, Bloomingdale, Streamwood,
          Bartlett, Carol Stream and Wheaton. By the late 1980’s the Chicago Suburban League officially disbanded and
          the BGYFL became the dominant private league in the north suburbs.`
      )}
    </div>
  )
}

export default AboutHistory
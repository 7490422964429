import React, {FC} from 'react'
//import Card from '@mui/material/Card/Card'
//import {CardContent, Grid, TextField, Typography} from '@mui/material'
import {useMultipleGamesByDivision} from '../hooks/multipleGameHooks'
import AdminReportScoreRow from './AdminReportScoreRow'

type AdminWeeklyScoreWidgetProps = {
  divisionId: string,
  week: string
}

const AdminWeeklyScoreWidget: FC<AdminWeeklyScoreWidgetProps> = ({ divisionId, week }) => {

  const {data: divisionalGames} = useMultipleGamesByDivision(globalThis.app.YEAR, divisionId, week)
  
 return (
    <>        
      {
        divisionalGames?.filter(game => game.week === week)
          .map((game) => (
            <AdminReportScoreRow
              key={game.gameId}
              game={game}
            />
          ))
      }          
    </>
  )
}

export default AdminWeeklyScoreWidget
import {ApiGame} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

interface IUseMultipleGamesByTeamId {
  year: string
  teamId: string
  overrideEnabled?: boolean
}

const getMultipleGamesByTeamId = async ({
                                  year,
                                  teamId,
                                }: IUseMultipleGamesByTeamId) => {
  console.log('FETCH: Fetching Multiple Games - ', year, teamId)
  const {data} = await apiInterface.get<{ games: ApiGame[] }>('getGames', {
    params: {
      year,
      teamId
    }
  })
  
  return data.games
}

export const useInvalidateMultipleGames = ({
                                             year,
                                             teamId
                                           }: IUseMultipleGamesByTeamId) => {
  const queryValues = [
    queryKeys.GET_MULTIPLE_GAMES,
    year,
    teamId
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateMultipleGames = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateMultipleGames
  }
}

export const useMultipleGamesByTeamId = ({
                                                   year,
                                                   teamId,
                                                   overrideEnabled = true
                                                 }: IUseMultipleGamesByTeamId) => {
  const {queryValues} = useInvalidateMultipleGames({
    year,
    teamId
  })
  
  return useQuery<ApiGame[]>(
    queryValues,
    () => getMultipleGamesByTeamId({year, teamId}),
    {
      ...queryOptions,
      enabled: overrideEnabled && !!year,
    }
  )
}


import {useContext, useEffect} from 'react'
import {AuthContext, IAuth} from '../Admin/authContext'
import {useNavigate} from 'react-router-dom'

const AdminLogout = () => {
  
  const {signOut}: IAuth = useContext(AuthContext)
  const navigate = useNavigate()
  
  useEffect(() => {
    signOut()
    navigate('/AdminSignIn')
    
  }, [navigate, signOut])
  
  return null
};

export default AdminLogout
import React, {FC} from 'react'
import {useParams} from 'react-router-dom'
import Layout from './Layout/Layout'
import {getImage, lowerCaseAndUnderscore, parseTeamId} from './utils/utils'
import StandingsWidget from './widgets/StandingsWidget'
import TeamSchedule from './TeamSchedule'
import {Avatar, Card, CardHeader, Grid, Typography} from '@mui/material'
import {useTeam} from './hooks/useTeam'
import {useOrganization} from './hooks/useOrganization'

const TeamPage: FC = () => {
  const {teamId} = useParams()
  const {year, divisionId, teamName} = parseTeamId(teamId)
  const {data: teamInfo} = useTeam({
    year: lowerCaseAndUnderscore(year),
    divisionId: lowerCaseAndUnderscore(divisionId),
    teamName: lowerCaseAndUnderscore(teamName),
  })
  const {data: organizationInfo} = useOrganization({organizationId: teamInfo?.organizationId || ''})
  
  return (
    <Layout>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card variant={'outlined'}>
            <CardHeader
              avatar={
                <Avatar src={getImage(organizationInfo?.organizationId)}/>
              }
              title={
                <Typography variant="h5" component="div" noWrap display="inline">
                  {`${divisionId} ${teamName} Team Page`}
                </Typography>
              }
              subheader={organizationInfo?.nickname}
            />
          </Card>
        </Grid>
        <Grid item lg={7} md={6} xs={12}>
          <TeamSchedule
            year={year}
            divisionId={divisionId}
            teamName={teamName}
            organizationId={organizationInfo?.organizationId}
          />
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <StandingsWidget year={year} divisionId={divisionId}/>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default TeamPage
import {useMultipleTeams} from './useMultipleTeams'

const useMultipleTeamsByDivision = (year: string, division: string) => {
  return useMultipleTeams({year, divisionId: division})
}

const useMultipleTeamsByTeam = (year: string, team: string) => {
  return useMultipleTeams({year, teamId: team})
}

const useMultipleTeamsByOrganization = (year: string, organization?: string) => {
  return useMultipleTeams({year, organizationId: organization})
}

export {
  useMultipleTeamsByDivision,
  useMultipleTeamsByTeam,
  useMultipleTeamsByOrganization,
}
import React, {FC} from 'react'

const BillGeorge: FC = () => {
  
  const blockStatement = (text: string): JSX.Element => {
    return (
      <div className={'flex flex-row items-center pb-4'}>
        <span className={'text-l'}>{text}</span>
      </div>
    )
  }
  
  return (
    <div className={'m-8'}>
      <div className={'flex flex-row items-center justify-around'}>
        <img
          className={'w-1/4 p-8'}
          src={'https://cdn1.sportngin.com/attachments/photo/a797-152708821/ProFootall_Hall_of_Fame_Logo_large.png'}
          alt={'Pro Football Hall of Fame Logo'}
        />
        <img
          className={'w-1/2 p-8'}
          src={'https://cdn1.sportngin.com/attachments/photo/be12-152708826/Bill_George_large.png'}
          alt={'Bill George Stats'}
        />
      </div>
      {blockStatement(
        `Bill George was the Chicago Bears’ second-round draft pick in 1951. He began his pro football career the
        following year as a middle guard in the then-standard five-man defensive front. Two years later, in a game
        against the Philadelphia Eagles, George made a now historic move that permanently changed defensive strategy
        in the National Football League.`
      )}
      {blockStatement(
        `On passing plays, George’s job was to bump the center and then drop back. George, noting the Eagles success
         at completing short passes just over his head, decided to skip the center bump and drop back immediately. Two
         plays later he caught the first of his 18 pro interceptions. While no one can swear which middle guard in a
         five-man line first dropped back to play middle linebacker and create the classic 4-3 defense, George is the
         most popular choice.`
      )}
      {blockStatement(
        `Certainly he was the first to star at the middle linebacker position. It was his ability to diagnose and react
         quickly that led the Bears to believe he could move that step or two backwards and become a super middle
         linebacker – a demanding position requiring sometimes pass rushing and sometimes pass defense, along with
         sure tackling, the speed to move from sideline to sideline, the agility to avoid blockers, and a keen sense
         of anticipation.`
      )}
      {blockStatement(
        `During the 1950’s and early 1960’s, the Bears were known for their outstanding defensive teams and George
        was a major part reason for their fame. A ferocious all-out player, he was named All-NFL choice eight times – seven
        consecutive seasons (1955-1961) and then an eighth in 1963 when he was the leader of the defensive that figured
        in the Bears 14-10 championship win over the New York Giants.`
      )}
      {blockStatement(
        `He was also selected to play in eight consecutive Pro Bowls, 1955-1962. In addition to his eighteen career
        interceptions, George also recovered nineteen fumbles, and in 1954 scored 25 points on thirteen PATs and four
        field goals.`
      )}
    </div>
  )
}

export default BillGeorge
import React, {FC} from 'react'
import {Card, Grid} from '@mui/material'

const ScheduleHeaderRow: FC = () => {
  return (
    <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'} style={{ background: '#15508c', color: 'white' }}>
      <Grid container spacing={1} className={'flex justify-center items-center px-4'}>
        <Grid item lg={4} md={4} xs={4} className={'flex justify-left items-left text-left'}>
          <span>{'Home Team'}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={'flex justify-center items-center'}>
          <span>{'Score'}</span>
        </Grid>
        <Grid item lg={2} md={2} xs={2} className={'flex justify-center items-center'}>
          <span>{'.vs'}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={'flex justify-center items-center'}>
          <span>{'Score'}</span>
        </Grid>
        <Grid item lg={4} md={4} xs={4} className={'flex-end justify-right items-right text-right'}>
          <span>{'Away Team'}</span>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ScheduleHeaderRow
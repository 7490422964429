import React, {FC} from 'react'
import {Card, Grid, Hidden} from '@mui/material'
import {Link} from 'react-router-dom';
import {parseDivisionTeamName} from '../utils/utils'
import {ApiGame} from '../types/apiTypes'

type ScheduleGameRowProps = {
  gameInfo: ApiGame,
  setGameInfo?: (gameInfo: ApiGame) => void,
  inEditMode?: boolean
}

const ScheduleGameRow: FC<ScheduleGameRowProps> = ({gameInfo, setGameInfo, inEditMode = false}) => {
  const {
    homeTeamName,
    homeTeamScore,
    awayTeamName,
    awayTeamScore,
    divisionId,
    year,
    gameDate, 
    gameTime,
    fieldName,
    city,
    GoogleUrl,
//    week
  } = gameInfo

  const getGameDirectionInfo = (type: string):string => {
    const [month, day, year] = gameDate.split('-')
    let retval
    let dayTemp: Date = new Date(parseInt(`20${year}`), parseInt(month) - 1, parseInt(day))
    //let dayString = dayTemp.getDay() ? 'Saturday' : 'Sunday'

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    let dayString = weekday[dayTemp.getDay()];

    let monthDate = dayTemp.getMonth() + 1
    let dayDate = dayTemp.getDate()
    
    if (type === "long"){
      if ({fieldName}) {
        retval = `${dayString} (${monthDate}-${dayDate}) at ${gameTime} @ ${fieldName} in ${city} `
      } else {
        retval = `${dayString} (${monthDate}-${dayDate}) at ${gameTime} `
      }
    } else {
      if ({fieldName}) {
        retval = `${dayString} (${monthDate}-${dayDate}) ${gameTime} @ ${fieldName} `
      } else {
        retval = `${dayString} (${monthDate}-${dayDate}) `
      }
    }
    return retval
  }
  
  const clickCardHandler = () => {
    setGameInfo && setGameInfo(gameInfo)
  }

  return (
    <Card className={'p-2 my-1 w-full flex justify-center items-center'} onClick={clickCardHandler}>
      <Grid container spacing={1} className={'flex justify-center items-center px-4'}>
        <Grid item lg={4} md={4} xs={4} className={'flex justify-left items-left text-left'}>
          { inEditMode ?
              parseDivisionTeamName(`${divisionId}_${homeTeamName}`)
            :
              <Link to={`/teams/${year}-${divisionId}-${homeTeamName}`}>{parseDivisionTeamName(`${divisionId}_${homeTeamName}`)}</Link>
          }
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={'flex justify-center items-center'}>
          <span>{(divisionId !== 'flag' && divisionId !== '8u') ? homeTeamScore : '-'}</span>
        </Grid>
        <Grid item lg={2} md={2} xs={2} className={'flex justify-center items-center'}>
          <span>{'.vs'}</span>
        </Grid>
        <Grid item lg={1} md={1} xs={1} className={'flex justify-center items-center'}>
          <span>{(divisionId !== 'flag' && divisionId !== '8u') ? awayTeamScore : '-'}</span>
        </Grid>
        <Grid item lg={4} md={4} xs={4} className={'flex-end justify-right items-right text-right'}>
          {
            inEditMode ?
              parseDivisionTeamName(`${divisionId}_${awayTeamName}`)
            :
              <Link to={`/teams/${year}-${divisionId}-${awayTeamName}`}>{parseDivisionTeamName(`${divisionId}_${awayTeamName}`)}</Link>
          }
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={'flex justify-center items-left text-left'}>
          <Hidden only={['xs', 'sm', 'md']}>
            {getGameDirectionInfo("long")}
          </Hidden>
          <Hidden only={['lg', 'xl']}>
            {getGameDirectionInfo("short")}
          </Hidden>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={'flex justify-center items-left text-left'}>
          {'('}<a target='_blank' rel="noreferrer" href={GoogleUrl}>click here for map to field</a>{`)`}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ScheduleGameRow


/*

        </Grid>
        {
          inEditMode ?  
          <>
            <Grid item lg={12} md={12} xs={12} className={'flex justify-center items-left text-left'}>
              {'('}<a target='_blank' rel="noreferrer" href={GoogleUrl}>click here for map to field</a>{`)`}
            </Grid>
          </>
          : ''
        }
      </Grid>
*/
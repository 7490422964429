import React, {FC, useState} from 'react'
import {Card, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent} from '@mui/material'
import ScheduleHeaderRow from './ScheduleHeaderRow'
import ScheduleGameRow from './ScheduleGameRow'
import {ApiGame} from '../types/apiTypes'

type ScheduleWidgetProps = {
  games: ApiGame[],
  organizationId?: string
  setGameInfo?: (gameInfo: ApiGame) => void, 
  inEditMode?: boolean
}

const ScheduleWidget: FC<ScheduleWidgetProps> = ({
                                                   games,
                                                   organizationId,
                                                   setGameInfo,
                                                   inEditMode = false
                                                 }) => {
  
  const today = new Date()
  // @ts-ignore
  const dayOfYear = Math.floor((today - new Date(today.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24) - 4;
  // Offset to the correct week. Above we have to offset by 4 to account for days of the week
  const week = Math.floor(dayOfYear / 7) - 32
  
  const [selectedWeek, setTargetWeek] = useState<string>(Math.max(Math.min(week, 12), 1).toString())
  
  interface iWeek {value: string, label: string}
  let weeks: iWeek[]
  if (organizationId) {
    weeks = [
      {value: 'home', label: 'Home Games',},
      {value: 'away', label: 'Away Games',},
      ...globalThis.app.WEEKS
    ]
  } else {
    weeks = globalThis.app.WEEKS
  }
  
  const handleWeekChange = (event: SelectChangeEvent) => {
    setTargetWeek(event.target.value)
  }
  
  let targetGames: ApiGame[]
  
  if (selectedWeek === 'full') {
    targetGames = games
  } else if (organizationId && selectedWeek === 'home') {
    targetGames = games.filter(game => game.homeOrganizationId === organizationId)
  } else if (selectedWeek === 'away') {
    targetGames = games.filter(game => game.awayOrganizationId === organizationId)
  } else {
    targetGames = games.filter(game => game.week === selectedWeek)
  }
  
  const noTimeGames = targetGames.filter(x => !x.gameTime || x.gameTime === 'X')
  const timedGames = targetGames.filter(x => x.gameTime !== 'X')
  const addedTimeValue = timedGames.map(x => {
    const [a_month, a_day, a_year] = x.gameDate.split('-')
    const aDate = new Date(parseInt(`20${a_year}`), parseInt(a_month) - 1, parseInt(a_day))
    if (x.gameTime) {
      const [time, amPm] = x?.gameTime.split(' ')
      let [hour, minute] = time.split(':')
      hour = amPm === 'PM' ? String(parseInt(hour) + 12) : hour
      aDate.setHours(parseInt(hour), parseInt(minute))
    }
    return {
      ...x,
      epochTime: aDate.getTime()
    }
  })
  
  const sortedGames = addedTimeValue
    .sort((a, b) => {
      return a.epochTime - b.epochTime
    })
  
  const outputGames = [
    ...sortedGames,
    ...noTimeGames
  ]
  
  outputGames.forEach(game => {
    const [a_month, a_day, a_year] = game.gameDate.split('-')
    const aDate = new Date(parseInt(`20${a_year}`), parseInt(a_month) - 1, parseInt(a_day))
    if (game.gameTime) {
      const [time, amPm] = game?.gameTime.split(' ')
      let [hour, minute] = time.split(':')
      hour = amPm === 'PM' ? String(parseInt(hour) + 12) : hour
      aDate.setHours(parseInt(hour), parseInt(minute))
    }
    //console.log('=====> gameTime', game.gameId, aDate.getTime())
  })
  
  
  return (
    <Paper elevation={2} className={'w-full h-full flex flex-col px-4 py-2'}>
      <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'}
            style={{background: '#15508c', color: 'white', fontSize: '16pt'}}>
        {'Schedule'}
      </Card>
      <div className={'flex justify-center items-center py-4'}>
        <FormControl sx={{m: 1, minWidth: 120}} size="small">
          <InputLabel id="demo-select-small">Week</InputLabel>
          <Select
            label="week"
            onChange={handleWeekChange}
            value={selectedWeek}
          >
            {weeks.map((week) => (
              <MenuItem key={week.value} value={week.value}>
                {week.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <ScheduleHeaderRow/>
      {
        outputGames.map(
          (gameInfo, index) => {
            return (
              <ScheduleGameRow
                key={index}
                gameInfo={gameInfo}
                setGameInfo={setGameInfo}
                inEditMode={inEditMode}
              />
            )
          })
      }
    </Paper>
  )
}

export default ScheduleWidget


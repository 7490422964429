import React, {FC} from 'react'
import superBowlWinners from './superBowlWinners.json'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import {Link} from 'react-router-dom'

type SuperBowlWinner = {
  year: string
  ageGroup: string
  division: string
  winner: string
  runnerUp: string
}

const SuperBowlWinners: FC = () => {
  const winnersGroupedByYear: Record<number, SuperBowlWinner[]> = superBowlWinners.reduce(
    (acc: Record<number, SuperBowlWinner[]>, curr: SuperBowlWinner) => {
      const targetYear = parseInt(curr.year)
      if (!acc[targetYear]) {
        acc[targetYear] = []
      }
      acc[targetYear].push(curr)
      return acc
    }, {})
  
  return (
    <div className={'w-full flex flex-col justify-center items-center'}>
      {
        Object.keys(winnersGroupedByYear)
          .sort((a, b) => parseInt(b) - parseInt(a))
          .map(targetYear => {
            const winnersForYear = winnersGroupedByYear[Number(targetYear)]
              .map(({ageGroup, division, winner, runnerUp}) => {
                const divisionString = division.split(' ').join('-')
                
                const winnerName = winner.split(' ').join('-')
                const winnerId = `${targetYear}-${ageGroup}-${divisionString}-${winnerName}`
                
                const runnerUpName = runnerUp.split(' ').join('-')
                const runnerUpId = `${targetYear}-${ageGroup}-${divisionString}-${runnerUpName}`
                
                return (
                  <TableRow key={winnerId}>
                    <TableCell>{ageGroup}</TableCell>
                    <TableCell>{division}</TableCell>
                    <TableCell>
                      <Link to={`/teams/${winnerId}`}>
                        {winner}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/teams/${runnerUpId}`}>
                        {runnerUp}
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })
            
            return (
              <div className={'pb-4 w-4/5'} key={targetYear}>
                <span className={'text-4xl py-4'}>{targetYear}</span>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead className={'bg-bgyfl-blue'}>
                      <TableRow>
                        <TableCell style={{fontSize: '16px'}}>{'Age Group'}</TableCell>
                        <TableCell style={{fontSize: '16px'}}>{'Division'}</TableCell>
                        <TableCell style={{fontSize: '16px'}}>{'Winner'}</TableCell>
                        <TableCell style={{fontSize: '16px'}}>{'Runner-Up'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {winnersForYear}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )
          })
      }
    </div>
  )
}

export default SuperBowlWinners
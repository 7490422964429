import React, {FC} from 'react'
import {Executive} from '../types/types'
import ExecutiveCard from './ExecutiveCard'

const ExecutiveList: FC = () => {
  
  const executiveList: Executive[] = [
    {
      name: 'Jason Nash',
      title: 'President',
    },
    {
      name: 'John Italia',
      title: 'Vice President'
    },
    {
      name: 'Kathy Johnston',
      title: 'Secretary'
    },
    {
      name: 'Bob Ciccone',
      title: 'Treasurer'
    },
    {
      name: 'Craig Querrey',
      title: 'Gold Division Football Operations'
    },
    {
      name: 'Alex D\'Agostino',
      title: 'Silver Division Football Operations'
    },
    {
      name: '--',
      title: 'Director Weights and Measures'
    },
    {
      name: 'Chris Sokolowski',
      title: 'Scheduling / Competition'
    }
  ]
  
  return (
    <div>
      {
        executiveList.map((executive, index) => (
          <ExecutiveCard executiveInfo={executive} key={index}/>
        ))
      }
    </div>
  )
}

export default ExecutiveList
import React, {FC} from 'react'
import Card from '@mui/material/Card/Card'
import {CardContent, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import Layout from '../Layout/Layout'


const AdminHomePage: FC = () => {
  
  const generateLinkTile = (path: string, label: string): JSX.Element => {
    return (
      <Link to={path} className={'px-8'}>
        <Card variant={'outlined'}>
          <CardContent>
            <div className={'flex flex-row'}>
              <div className={'float: left'}>
                <Typography variant="h5" component="div">
                  {label}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </Link>
    )
  }
  
  return (
    <Layout>
      <div className={'flex flex-row items-center justify-center m-8'}>
        {generateLinkTile('/admin/organizationEdit', 'Edit Organization')}
        {generateLinkTile('/admin/createSchedule', 'Create Schedule')}
        {generateLinkTile('/admin/scheduleEdit', 'Edit Schedule')}
        {generateLinkTile('/admin/updateScores', 'Update Scores')}
      </div>
    </Layout>
  )
}

export default AdminHomePage
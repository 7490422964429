import React, {FC, useState} from 'react'
import Card from '@mui/material/Card/Card'
import {Box, CardContent, Grid, Typography, responsiveFontSizes, createTheme} from '@mui/material'
//import {updateGame} from '../hooks/useGame'
import {ApiGame} from '../types/apiTypes'
import {parseTeamName} from '../utils/utils'
//import {ThemeProvider} from '@emotion/react'
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
//import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

//import {AuthContext} from './authContext'

type AdminReportScoreRowProps = {
  game: ApiGame
}

// enum UpdateStatus {
//   IDLE = 'idle',
//   PENDING = 'pending',
//   SUCCESS = 'success',
//   ERROR = 'error'
// }

const AdminReportScoreRow: FC<AdminReportScoreRowProps> = ({game}) => {
  
  //const auth = useContext(AuthContext)
  
  const [homeTeamScore, setHomeTeamScore] = useState(game.homeTeamScore)
  const [awayTeamScore, setAwayTeamScore] = useState(game.awayTeamScore)
  
  let theme = createTheme()
  theme = responsiveFontSizes(theme)
  
  return (
    <Grid item lg={12} md={12} xs={12} rowSpacing={3} key={game.gameId}>
      <Card variant="outlined"  sx={{backgroundColor: Math.abs(parseInt(homeTeamScore!) - parseInt(awayTeamScore!)) > 25 ? 'lightyellow' : 'transparent', }}>
        <CardContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
          >
            <Grid container>
              <Grid item lg={5} md={5} xs={5}>
                <Grid container>
                  <Typography component="div" sx={{paddingRight: '20px'}}>{parseTeamName(game.homeTeamName)}</Typography>
                  <Typography component="div">{homeTeamScore}</Typography>
                </Grid>
              </Grid>
              <Grid item container lg={2} md={2} xs={2} alignItems="center" justifyContent="center">
                <Typography component="div">
                  {'vs.'}
                </Typography>
              </Grid>
              <Grid item lg={5} md={5} xs={5}>
                <Grid container>
                  <Typography component="div" sx={{paddingRight: '20px'}}>{parseTeamName(game.awayTeamName)}</Typography>
                  <Typography component="div">{awayTeamScore}</Typography>                
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default AdminReportScoreRow

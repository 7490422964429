import React, {FC} from 'react'
//import Card from '@mui/material/Card/Card'
//import {CardContent, Grid, TextField, Typography} from '@mui/material'
import {useMultipleGamesByOrganization} from '../hooks/multipleGameHooks'

import AdminUpdateScoreRow from './AdminUpdateScoreRow'
import {generalSortTeamsByDivision} from '../utils/utils'

type AdminUpdateScoreWidgetProps = {
  organizationId: string,
  week: string
}

const AdminUpdateScoreWidget: FC<AdminUpdateScoreWidgetProps> = ({ organizationId, week }) => {

  const {data: organizationGames} = useMultipleGamesByOrganization(globalThis.app.YEAR, organizationId, !!organizationId)
  
 return (
    <>        
      {
        organizationGames?.filter(game => game.week === week)
        .filter(game => ((game.homeOrganizationId === organizationId) || 
                         (game.homeOrganizationId === 'None' &&  game.awayOrganizationId === organizationId)))  /* away bye cases */
          .sort((a, b) => generalSortTeamsByDivision(a.divisionId, b.divisionId))
          .map((game) => (
            <AdminUpdateScoreRow
              key={game.gameId}
              game={game}
            />
          ))
      }          
    </>
  )
}

export default AdminUpdateScoreWidget

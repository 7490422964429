import Grid from '@mui/material/Grid/Grid'
import React, {FC} from 'react'
import DisplayOrganization from './widgets/DisplayOrganization'
import {ApiOrganization} from './types/apiTypes'
import {useAllOrganizations} from './hooks/useAllOrganizations'
import Layout from './Layout/Layout'

const OrganizationListPage: FC = () => {
  const sortOrganizations = (innerOrganizations: ApiOrganization[]) => {
    return innerOrganizations.sort((a, b) => {
      return a.organizationName.localeCompare(b.organizationName)
    })
  }

  const {data: organizations} = useAllOrganizations()
  
  return (
    <Layout>
      <div className={'p-4'}>
        <Grid container justifyContent="space-around" spacing={3}>
          {sortOrganizations(organizations || []).map((organizationInfo, index) => {
            return <DisplayOrganization key={index} organizationInfo={organizationInfo}/>
          })}
        </Grid>
      </div>
    </Layout>
  )
}

export default OrganizationListPage
import React, {FC} from 'react'
import TeamListWidget from './TeamListWidget'
import StandingTeamCard from './StandingTeamCard'
import {Card, Grid} from '@mui/material'
import {useMultipleTeamsByDivision} from '../hooks/multipleTeamHooks'
import {ApiTeam} from '../types/apiTypes'

type StandingsWidgetProps = {
  year: string
  divisionId: string
}

const StandingsWidget: FC<StandingsWidgetProps> = ({year, divisionId}) => {
  
  const {data: teams} = useMultipleTeamsByDivision(year, divisionId)

  const sortTeams = (innerTeams: ApiTeam[]): ApiTeam[] => {
    return innerTeams?.sort((
      {wins: aWins = 0, losses: aLosses = 0, ties: aTies = 0, playoffPoints: aPlayoffPoints = 0},
      {wins: bWins = 0, losses: bLosses = 0, ties: bTies = 0, playoffPoints: bPlayoffPoints = 0}
    ) => {
      // Janky, but covering our bases in case we don't have the data since this isn't guaranteed anymore
      // if (aWins + aLosses + aTies === 0){
      //   return 1
      // } else if (bWins + bLosses + bTies === 0){
      //   return -1
      // }
      // return bWins - aWins || bPlayoffPoints - aPlayoffPoints

      const aGD = aWins - aLosses
      const bGD = bWins - bLosses
      if (aGD !== bGD) {
        return bGD - aGD
      }
      return bPlayoffPoints - aPlayoffPoints
    })
  }
  
  //const headerRowClassNames = 'flex justify-center items-center fontSize:12pt'
  const rowClassNamesCenter = 'flex justify-center items-center'
  const rowClassNamesLeft = 'flex justify-left items-left'
  
  return (
    <>
      <Card>
        <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'} style={{ background: '#15508c', color: 'white', fontSize: '16pt' }}>
          {'Standings'}
        </Card>
        <Card className={'p-2 my-1 w-full flex justify-center items-center bgyfl-blue'} style={{ background: '#15508c', color: 'white' }}>
          <Grid container spacing={1} className={'flex justify-center items-center px-4'}>
            <Grid item lg={3} md={3} xs={3} className={rowClassNamesCenter}>
              <span>{'Rank'}</span>
            </Grid>
            <Grid item lg={4} md={4} xs={4} className={rowClassNamesLeft}>
              <span>{'Team'}</span>
            </Grid>
            <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
              <span>{'W'}</span>
            </Grid>
            <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
              <span>{'L'}</span>
            </Grid>
            <Grid item lg={1} md={1} xs={1} className={rowClassNamesCenter}>
              <span>{'T'}</span>
            </Grid>
            <Grid item lg={2} md={2} xs={2} className={rowClassNamesCenter}>
              <span>{'Playoff Points'}</span>
            </Grid>
          </Grid>
        </Card>
        <TeamListWidget teamInfo={sortTeams(teams || [])} TeamCardComponent={StandingTeamCard}/>
      </Card>
    </>
  )
}

export default StandingsWidget
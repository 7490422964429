import {Division, Field, WeightClass} from '../types/types'

export const teamNames: string[] = [
  'Arlington Heights', 'Bartlett', 'Bensenville', 'Bloomingdale', 'Carol Stream', 'Downers Grove', 'Elk Grove Village',
  'Elmhurst', 'Glen Ellyn', 'Hanover Park', 'Hinsdale', 'Lemont', 'Lombard', 'Lyons', 'Naperville',
  'Oak Park River Forest', 'Oswego', 'Palatine', 'Park Ridge', 'Plainfield', 'Tri City', 'Wheaton'
]

export const weightClasses: WeightClass[] = [
  '9U', '10U', '11U', 'JV', 'Senior'
]

export const divisionNames: Division[] = [
  'Gold D1', 'Gold D2', 'Silver'
]

const generateMockField = (organizationName: string): Field => {
  return {
    fieldName: `${organizationName} Field`,
    numFields: 2,
    lights: true,
  }
}

type Team = {
  year: number
  teamId: string
  teamName: string
  teamTown: string
  coachEmail: string
  parentEmail: string
  homeField: Field
  division: Division
  weightClass: WeightClass
}

const getTotalTeams = (): Team[] => {
  return teamNames.flatMap((teamName, teamIndex) => {
    return weightClasses.flatMap((weightClass, weightIndex) => {
      return divisionNames.flatMap((division, divisionIndex) => {
        const codifiedTeamId = `${teamIndex}-${weightIndex}-${divisionIndex}`
        return {
          year: globalThis.app.YEAR_NUM,
          teamId: codifiedTeamId,
          teamName,
          teamTown: teamName,
          coachEmail: `${codifiedTeamId}_coach@gmail.com`,
          parentEmail: `${codifiedTeamId}_parent@gmail.com`,
          homeField: generateMockField(teamName),
          division,
          weightClass
        }
      })
    })
  })
}

export const totalTeams = getTotalTeams()


import React, {FC} from 'react';
import './App.css';
import {HashRouter, Route, Routes} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from 'react-query'

import './globalThis.ts';

import AuthProvider, { AuthIsSignedIn } from './Pages/Admin/authContext'

import HomePage from './Pages/HomePage';
import OrganizationListPage from './Pages/OrganizationListPage'
import OrganizationPage from './Pages/OrganizationPage'
import TeamListPage from './Pages/TeamListPage'
import TeamPage from './Pages/TeamPage'
import AboutPage from './Pages/About/AboutPage'
import DivisionPage from './Pages/DivisionPage'
import DivisionList from './Pages/DivisionList'
import AdminSignIn from './Pages/Admin/AdminSignIn'
import SuperAdminHomePage from './Pages/Admin/SuperAdminHomePage'
import AdminCreateSchedulePage from './Pages/Admin/AdminCreateSchedulePage'
import AdminEditSchedulePage from './Pages/Admin/AdminEditSchedulePage'
import AdminEditOrganiztionPage from './Pages/Admin/AdminEditOrganizationPage'
import AdminUpdateScorePage from './Pages/Admin/AdminUpdateScorePage'
import AdminLogout from './Pages/Admin/AdminLogout'
import AdminWeeklyScoreReport from './Pages/Admin/AdminWeeklyScoreReport'

const queryClient = new QueryClient()

const PublicRoutes: FC = () => (
  <Routes>
    <Route path={'/'} element={<HomePage/>}/>  
    <Route path={'/organizations'} element={<OrganizationListPage/>}/>
    <Route path={'/organizations/:organizationId'} element={<OrganizationPage/>}/>
    <Route path={'/divisions'} element={<DivisionList />}/>
    <Route path={'/divisions/:divisionName'} element={<DivisionPage />}/>
    <Route path='/teams' element={<TeamListPage/>}/>
    <Route path='/teams/:teamId' element={<TeamPage/>}/>
    <Route path={'/about/history'} element={<AboutPage tabKey={0}/>}/>
    <Route path={'/about/billGeorge'} element={<AboutPage tabKey={1}/>}/>
    <Route path={'/about/franchises'} element={<AboutPage tabKey={2}/>}/>
    <Route path={'/about/executives'} element={<AboutPage tabKey={3}/>}/>
    <Route path={'/about/superBowlWinners'} element={<AboutPage tabKey={4}/>}/>
    <Route path={'/about'} element={<AboutPage/>}/>
    <Route path={'/AdminSignIn'} element={<AdminSignIn />}/>
    <Route path={'/logout'} element={<AdminLogout />}/>
  </Routes>
)

const AdminRoutes: FC = () => (
  <Routes>
    <Route path={'/superadmin'} element={<SuperAdminHomePage />}/>
    <Route path={'/admin/organizationEdit'} element={<AdminEditOrganiztionPage />}/>
    <Route path={'/admin/scheduleEdit'} element={<AdminEditSchedulePage />}/>
    <Route path={'/admin/createSchedule'} element={<AdminCreateSchedulePage />}/>
    <Route path={'/admin/updateScores'} element={<AdminUpdateScorePage />}/>
    <Route path={'/admin/weeklyScoreReport'} element={<AdminWeeklyScoreReport />}/>    
  </Routes>
)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <HashRouter>
          <AuthIsSignedIn>
            <AdminRoutes/>
          </AuthIsSignedIn>
          <PublicRoutes/>
        </HashRouter>
      </AuthProvider>
      <link rel={'icon'} href={'https://assets.ngin.com/site_files/36283/favicon.ico'}/>
    </QueryClientProvider>
  );
}

export default App;

/*
<Route path={'/practice'} element={<PracticePage/>}/>

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/verify" component={VerifyCode} />
      <Route path="/requestcode" component={RequestCode} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/" component={Landing} />
    </Switch>
  </Router>
)

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/" component={Home} />
    </Switch>
  </Router>
)

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  </ThemeProvider>



*/
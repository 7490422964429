import {ApiTeam} from '../types/apiTypes'
import {useQuery, useQueryClient} from 'react-query'
import {apiInterface, queryKeys, queryOptions} from '../constants/queryConstants'

interface IUseMultipleTeams {
  year: string
  divisionId?: string
  teamId?: string
  organizationId?: string
}

const getMultipleTeams = async ({
                                  year,
                                  divisionId,
                                  teamId,
                                  organizationId
                                }: IUseMultipleTeams) => {
  console.log('FETCH: Fetching Multiple Teams - ', year, divisionId, teamId, organizationId)
  const {data} = await apiInterface.get<{ teams: ApiTeam[] }>('getTeams', {
    params: {
      year,
      divisionId,
      teamId,
      organizationId,
    }
  })
  
  return data.teams
}

export const useInvalidateMultipleTeams = ({
                                             year,
                                             divisionId,
                                             teamId,
                                             organizationId
                                           }: IUseMultipleTeams) => {
  const queryValues = [
    queryKeys.GET_MULTIPLE_TEAMS,
    year,
    divisionId,
    teamId,
    organizationId,
  ]
  
  const queryClient = useQueryClient()
  
  const invalidateMultipleTeams = async () => {
    await queryClient.invalidateQueries(queryValues)
  }
  
  return {
    queryValues,
    invalidateMultipleTeams
  }
}

export const useMultipleTeams = ({
                                   year,
                                   divisionId,
                                   teamId,
                                   organizationId
                                 }: IUseMultipleTeams) => {
  const {queryValues} = useInvalidateMultipleTeams({year, divisionId, teamId, organizationId})
  return useQuery<ApiTeam[]>(
    queryValues,
    () => getMultipleTeams({year, divisionId, teamId, organizationId}),
    {
      ...queryOptions,
      enabled: !!year,
    }
  )
}
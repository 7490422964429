import {totalTeams} from '../constants/teams'

import arlingtonHeights from '../../images/arlington_heights_small.png'
import bartlett from '../../images/bartlett_small.png'
import bensenville from '../../images/bensenville_small.png'
import bloomingdale from '../../images/bloomingdale_small.png'
import carolStream from '../../images/carol_stream_small.png'
import downersGrove from '../../images/downers_grove_small.png'
import elkGrove from '../../images/elk_grove_small.png'
import elmhurst from '../../images/elmhurst_small.png'
import glenEllyn from '../../images/glen_ellyn_small.png'
import hanoverPark from '../../images/hanover_park_small.png'
import hinsdale from '../../images/hinsdale_small.png'
import lemont from '../../images/lemont_small.png'
import lombard from '../../images/lombard_small.png'
import lyons from '../../images/lyons_small.png'
import naperville from '../../images/naperville_small.png'
import oakPark from '../../images/oak_park_small.png'
import oswego from '../../images/oswego_small.png'
import palatine from '../../images/palatine_small.png'
import plainfield from '../../images/plainfield_small.png'
import parkRidge from '../../images/park_ridge_small.png'
import triCity from '../../images/tri_city_small.png'
import wheaton from '../../images/wheaton_small.png'
import {ApiTeam} from '../types/apiTypes'

import flag_all from '../../images/FLAG.png'
import eight from '../../images/8.png'
import nine_G from '../../images/9G.png'
import nine_G2 from '../../images/9G2.png'
import nine_S from '../../images/9S.png'
import ten_G from '../../images/10G.png'
import ten_G2 from '../../images/10G2.png'
import ten_S from '../../images/10S.png'
import eleven_G from '../../images/11G.png'
import eleven_G2 from '../../images/11G2.png'
import eleven_S from '../../images/11S.png'
import jv_G from '../../images/JVG.png'
import jv_G2 from '../../images/JVG2.png'
import jv_S from '../../images/JVS.png'
import varsity_G from '../../images/VG.png'
import varsity_G2 from '../../images/VG2.png'
import varsity_S from '../../images/VS.png'


export const getTeamName = (teamId: string): string => {
  const foundTeam = totalTeams.find(x => x.teamId === teamId)
  if (!foundTeam) return 'Unknown Team Name'
  return `${foundTeam.teamTown} ${foundTeam.weightClass} ${foundTeam.division}`
}

export const capitalizeWord = (word: string): string => {
  return (word === 'jv') ? word.toUpperCase(): word.charAt(0).toUpperCase() + word.slice(1)
}

export const parseTeamId = (teamId: string | undefined): Record<string, string> => {
  const emptyTeamResponse = {
    year: '',
    teamName: '',
    divisionId: '',
  }
  if (!teamId) {
    console.log(`No teamId found`)
    return emptyTeamResponse
  }
  
  const splitString = teamId.split('-')
  if (splitString.length < 3) {
    console.log(`Error parsing teamId: ${teamId}`)
    return emptyTeamResponse
  }
  
  return {
    year: splitString[0],
    divisionId: parseDivisionId(splitString[1]),
    teamName: parseTeamName(splitString[2]),
  }
}

export const parseOrganizationId = (organizationId: string | undefined): string => {
  if (!organizationId) {
    console.log(`No organizationId found`)
    return 'Unknown Organization'
  }
  
  const splitString = organizationId.split('_')
  
  return splitString.map(x => capitalizeWord(x)).join(' ')
}

export const parseDivisionTeamName = (divisionTeamName: string): string => {
  const splitWords = divisionTeamName.split('_')
  const division = splitWords[0]
  const divisionString = division === 'senior' ? capitalizeWord(division) : division.toUpperCase()
  let teamName = splitWords.slice(1).map(capitalizeWord).join(' ')
  return `${divisionString} ${teamName}`
}

export const parseTeamName = (teamName: string): string => {
  const splitWords = teamName.split('_')
  return splitWords.map(capitalizeWord).join(' ')
}

export const parseDivisionId = (divisionId: string): string => {
  const splitWords = divisionId.split('_')
  return splitWords.map(capitalizeWord).join(' ')
}

export const lowerCaseAndUnderscore = (word: string): string => {
  return word.toLowerCase().replace(/ /g, '_').replace('-', '_')
}

export const generalSortTeamsByDivision = (a: string, b: string): number => {
  const priorityAgeGroup = ['flag', '8u', '9u', '10u', '11u', 'jv', 'varsity']
  const priorityDivision = ['d1', 'd2', 'silver']
  const aDivisionStrings = a.split('_')
  const bDivisionStrings = b.split('_')
  const aAgeGroup = priorityAgeGroup.indexOf(aDivisionStrings[0])
  const bAgeGroup = priorityAgeGroup.indexOf(bDivisionStrings[0])
  const aDivision = priorityDivision.indexOf(aDivisionStrings.slice(1).join(' '))
  const bDivision = priorityDivision.indexOf(bDivisionStrings.slice(1).join(' '))
  return aAgeGroup - bAgeGroup || aDivision - bDivision
}

export const sortTeamsByDivision = (teamInfo: ApiTeam[]): ApiTeam[] => {
  return teamInfo.sort((a, b) => {
    return generalSortTeamsByDivision(a.divisionId, b.divisionId)
  })
}

export const sortTeamsByTeamName = (teamInfo: ApiTeam[]): ApiTeam[] => {
  return teamInfo.sort((a, b) => {
    const aTeamName = a.teamName.toLowerCase()
    const bTeamName = b.teamName.toLowerCase()
    return aTeamName > bTeamName ? 1 : -1
  })
}

export const getImage = (organizationId: string | undefined): string => {
  if (!organizationId) return ''
  return {
    'arlington_heights': arlingtonHeights,
    'bartlett': bartlett,
    'bensenville': bensenville,
    'bloomingdale': bloomingdale,
    'carol_stream': carolStream,
    'downers_grove': downersGrove,
    'elk_grove': elkGrove,
    'elmhurst': elmhurst,
    'glen_ellyn': glenEllyn,
    'hanover_park': hanoverPark,
    'hinsdale': hinsdale,
    'lemont': lemont,
    'lombard': lombard,
    'lyons': lyons,
    'naperville': naperville,
    'oak_park': oakPark,
    'oswego': oswego,
    'palatine': palatine,
    'park_ridge': parkRidge,
    'plainfield': plainfield,
    'tri_city': triCity,
    'wheaton': wheaton,
  }[organizationId] || wheaton
}

export const getImageForDivision = (divisionId: string | undefined): string => {
  if (!divisionId) return ''
    switch (divisionId) {
      case 'flag':
        return flag_all
      case '8u':
        return eight
      case '9u_gold_d1':
        return nine_G
      case '9u_gold_d2':
        return nine_G2
      case '9u_silver':
        return nine_S
      case '10u_gold_d1':
        return ten_G
      case '10u_gold_d2':
        return ten_G2
      case '10u_silver':
        return ten_S
      case '11u_gold_d1':
        return eleven_G
      case '11u_gold_d2':
        return eleven_G2
      case '11u_silver':
        return eleven_S
      case 'jv_gold_d1':
        return jv_G
      case 'jv_gold_d2':
        return jv_G2
      case 'jv_silver':
        return jv_S
      case 'varsity_gold_d1':
        return varsity_G
      case 'varsity_gold_d2':
        return varsity_G2
      case 'varsity_silver':
        return varsity_S
      default:
        return ''
    } 
}
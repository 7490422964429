import React, {FC} from 'react'
import {Paper} from '@mui/material'
import DefaultTeamCard from './/DefaultTeamCard'
import {ApiTeam} from '../types/apiTypes'

type TeamListWidgetProps = {
  teamInfo: ApiTeam[],
  showCoachInfo?: boolean,
  isEditable?: boolean, 
  TeamCardComponent?: FC<{ teamInfo: ApiTeam; rank?: number }>
}

const TeamListWidget: FC<TeamListWidgetProps> = ({
                                                   teamInfo,
                                                   showCoachInfo,
                                                   isEditable,
                                                   TeamCardComponent = DefaultTeamCard
                                                 }) => {
  

  return (
    <Paper elevation={2} className={'px-4 py-2'}>
      {
        teamInfo.map((singleTeamInfo, index) => <TeamCardComponent teamInfo={singleTeamInfo} rank={index + 1} key={index} showCoachInfo={showCoachInfo} isEditable={isEditable}/>)
      }
    </Paper>
  )
}

export default TeamListWidget
import React, {FC, useContext} from 'react'
import {AppBar, Container, Toolbar, Box, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import {Link} from 'react-router-dom'
import BGYFL_LOGO from '../../images/BGYFL-Logo.png'
import {AuthContext, IAuth, AuthStatus} from '../Admin/authContext'


const Header: FC = () => {

  const generateLink = (path: string, label: string) => {
    return (
      <Link to={path} className={'h-1/2 w-1/2 flex justify-center items-center'}>
          <span className={'text-xl'}>
            {label}
          </span>
      </Link>
    )
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  
  const { authStatus }: IAuth = useContext(AuthContext)
  
  return (
    <>
      <AppBar position="static" style={{background: '#15508c'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {
              // here is where large screen app bar is showing
            }
              <>
                <Box
                  component="img"
                  sx={{
                    height: 60,
                    display: {xs: 'none', md: 'flex'},
                  }}
                  alt="Bill George Youth Football"
                  src={BGYFL_LOGO}
                />
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  textOverflow="ellipsis"
                  sx={{
                    mr: 2,
                    display: {xs: 'none', md: 'flex'},
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  Bill George Youth Football
                </Typography>
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                  {generateLink('/', 'Home')}
                  {generateLink('/about', 'About')}
                  {
                    authStatus === AuthStatus.SignedIn ?
                      generateLink('/logout', 'Logout') :
                      generateLink('/AdminSignIn', 'Login')
                  }
                </Box>
              </>
            {
              // here is where the 'small screen' app bar is showing
            }
              <>
                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon/>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: {xs: 'block', md: 'none'},
                    }}
                  >
                    <MenuItem>
                      {generateLink('/', 'Home')}
                    </MenuItem>
                    <MenuItem>
                      {generateLink('/about', 'About')}
                    </MenuItem>
                    <MenuItem>
                      {
                        authStatus === AuthStatus.SignedIn ?
                        generateLink('/logout', 'Logout') :
                        generateLink('/AdminSignIn', 'Login')
                      }
                    </MenuItem>
                  </Menu>
                </Box>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href=""
                  textOverflow="ellipsis"
                  sx={{
                    mr: 2,
                    display: {xs: 'flex', md: 'none'},
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  Bill George Youth Football
                </Typography>
              </>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Header